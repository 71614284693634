import {
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Link,
  Rating,
  Typography
} from '@material-ui/core';
// material
import { styled } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { React, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Link as RouterLink } from 'react-router-dom';
import Label from 'src/components/Label';
import { DISCOUNT } from 'src/constants/constant';
// utils
import { formatCurrency } from 'src/utils/formatcurrency';
import { UrlConfigs } from 'src/utils/UrlConfig';
import { CoverImgStyle, IntroStyle, SubTitleStyle, TitleStyle } from './TopCourseCard';

// ----------------------------------------------------------------------

const CardMediaStyle = styled('div')({
  position: 'relative',
  paddingTop: 'calc(100% * 3 / 4)'
});

const AvatarStyle = styled(Avatar)(({ theme }) => ({
  zIndex: 9,
  width: 32,
  height: 32,
  position: 'absolute',
  left: theme.spacing(3),
  bottom: theme.spacing(-2)
}));

const InfoStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  marginTop: theme.spacing(1),
  color: theme.palette.text.disabled
}));

// ----------------------------------------------------------------------

SecondCourseCard.propTypes = {
  post: PropTypes.object,
  index: PropTypes.number
};

export default function SecondCourseCard({ lessons, index }) {
  const [anchor, setAnchor] = useState(null);

  const handlePopoverOpen = (event) => {
    event.currentTarget.style.transform = 'scale(1.05)';
  };

  const handlePopoverClose = (event) => {
    event.currentTarget.style.transform = 'none';
  };

  const { thumbnail, name, price, introduction, subtitle, level, _id, seoTitle } = lessons;
  // 2 khoá học đầu bự hơn
  const latestPostLarge = [0, 1].indexOf(index) !== -1;
  // const latestPost = index === 2 || index === 3;

  // const POST_INFO = [
  //   { number: comment, icon: messageCircleFill },
  //   { number: view, icon: eyeFill },
  //   { number: share, icon: shareFill }
  // ];
  const [rateValue, setRateValue] = useState(4.5);

  return (
    <Grid item xs={12} md={6} lg={3}>
      <Card
        sx={{
          position: 'relative',
          transition: 'all .5s',
          background: '#fffafa',
          height: 'fit-content'
        }}
        title={introduction}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <CardActionArea
          LinkComponent={RouterLink}
          to={`${UrlConfigs.LESSON_DETAIL}/${seoTitle}`}
          sx={{ height: '100%' }}
        >
          {DISCOUNT > 0 && (
            <Label
              variant="filled"
              color="warning"
              sx={{
                zIndex: 9,
                top: 16,
                right: 16,
                position: 'absolute',
                textTransform: 'uppercase'
              }}
            >
              -{DISCOUNT}%
            </Label>
          )}
          {/* <SvgIconStyle
            color="paper"
            src="/static/icons/shape-avatar.svg"
            sx={{
              width: 80,
              height: 36,
              zIndex: 9,
              bottom: -15,
              position: 'absolute'
            }}
          /> */}

          {/* <AvatarStyle alt={author.name} src={author.avatarUrl} /> */}
          <CoverImgStyle alt={name} src={thumbnail} />

          {/* <Popper
            open={Boolean(anchor)}
            anchorEl={anchor}
            placement="right-start"
            transition
            disablePortal={false}
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={{ enter: 350 }}>
                <PaperStyle>
                  <Typography
                    sx={{ pt: 3, height: 'auto' }}
                    variant="h4"
                    textAlign="center"
                    color="red"
                  >
                    {name}
                  </Typography>
                  <Typography sx={{ p: 1.5 }}>
                    Khóa học đem lại hiểu biết đầy đủ về từng kỹ năng của kì thi IELTS và những kỹ
                    thuật làm bài cam kết đem lại hiệu quả. Người học sẽ nắm được
                  </Typography>
                  <List sx={{ marginBottom: '50px' }}>
                    <ListItem>
                      <ListItemIcon>
                        <BeenhereIcon />
                      </ListItemIcon>
                      <ListItemText primary="Sử dụng từ vựng phức tạp, collocation, các idioms phù hợp với kì thi IELTS" />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <BeenhereIcon />
                      </ListItemIcon>
                      <ListItemText primary="Cách chinh phục bài thi IELTS bằng kỹ năng độc quyền của The Forum" />
                    </ListItem>
                  </List>
                  <Link
                    underline="none"
                    href={`${UrlConfigs.LESSON_DETAIL}/${_id}`}
                    sx={{
                      alignSelf: 'end',
                      pt: 2,
                      position: 'absolute',
                      bottom: '30px',
                      width: '88%'
                    }}
                  >
                    <Button fullWidth target="_blank" variant="outlined">
                      Nhận
                    </Button>
                  </Link>
                </PaperStyle>
              </Fade>
            )}
          </Popper> */}

          <CardContent>
            <InfoStyle>
              <div>
                <TitleStyle
                  variant="h6"
                  sx={{
                    ...{ height: 'fit-content' }
                  }}
                >
                  {name}
                </TitleStyle>
                <SubTitleStyle>{ReactHtmlParser(subtitle)}</SubTitleStyle>
                <Box display="flex">
                  <Typography
                    variant="h4"
                    gutterBottom
                    sx={{ color: 'red', display: 'block', mr: 1 }}
                  >
                    {formatCurrency(price * (1 - DISCOUNT / 100))}
                  </Typography>
                  {DISCOUNT > 0 && (
                    <Typography
                      variant="body"
                      gutterBottom
                      sx={{ textDecoration: 'line-through', alignSelf: 'center' }}
                    >
                      {formatCurrency(price)}
                    </Typography>
                  )}
                </Box>

                <Box component="fieldset" borderColor="transparent">
                  <Rating
                    name="simple-controlled"
                    value={rateValue}
                    onChange={(event, newValue) => {
                      setRateValue(newValue);
                    }}
                    readOnly
                    precision={0.5}
                  />
                </Box>
              </div>
              <IntroStyle variant="body2" mb="4em">
                {introduction}
              </IntroStyle>
            </InfoStyle>

            <Link component="div" underline="none" sx={{ alignSelf: 'end', width: '40%', pt: 3 }}>
              <Button
                fullWidth
                sx={{
                  width: '50%',
                  typography: 'h5',
                  right: '1em',
                  bottom: 0,
                  position: 'absolute',
                  mb: 3
                }}
                target="_blank"
                variant="outlined"
              >
                Nhận
              </Button>
            </Link>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
}
