export const TOGGLE_DIALOG_SUPPORT = 'TOGGLE_DIALOG_SUPPORT';
export const TOGGLE_DIALOG_COD = 'TOGGLE_DIALOG_COD';

export const SET_CONTENT_DIALOG_SUPPORT = 'SET_CONTENT_DIALOG_SUPPORT';
export const SET_CONTENT_DIALOG_COD = 'SET_CONTENT_DIALOG_COD';

export const SEND_COD_FORM = 'SEND_COD_FORM';
export const SEND_COD_FORM_SUCCESSFULLY = 'SEND_COD_FORM_SUCCESSFULLY';
export const SEND_COD_FORM_FAILED = 'SEND_COD_FORM_FAILED';

export function ToggleDialogSupport() {
  return {
    type: TOGGLE_DIALOG_SUPPORT
  };
}

export function SetContentDialogSupport(content) {
  return {
    type: SET_CONTENT_DIALOG_SUPPORT,
    payload: content
  };
}
export function ToggleDialogCod() {
  return {
    type: TOGGLE_DIALOG_COD
  };
}

export function SetContentDialogCod(content) {
  return {
    type: SET_CONTENT_DIALOG_COD,
    payload: content
  };
}

export function SendCodFormSuccesfully(data) {
  return {
    type: SEND_COD_FORM_SUCCESSFULLY,
    payload: data
  };
}

export function SendCodFormFailed(errMsg) {
  return {
    type: SEND_COD_FORM_FAILED,
    errorMessage: errMsg
  };
}
