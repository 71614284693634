import { Skeleton, Typography } from '@material-ui/core';
import { MHidden } from 'src/components/@material-extend';

export const LoadingCoursePreview = () => (
  <>
    <MHidden width="mdDown">
      <Skeleton
        width="100%"
        height="80vh"
        sx={{ top: '2vh', position: 'absolute', left: '5vw', maxWidth: '62vw' }}
      >
        <Typography>.</Typography>
      </Skeleton>
      <Skeleton
        width="100%"
        height="20vh"
        sx={{ top: '70vh', position: 'absolute', left: '5vw', maxWidth: '62vw' }}
      >
        <Typography>.</Typography>
      </Skeleton>

      <Skeleton
        width="100%"
        height="80vh"
        sx={{ top: '2vh', position: 'absolute', left: '70vw', maxWidth: '25vw' }}
      >
        <Typography>.</Typography>
      </Skeleton>

      <Skeleton
        width="100%"
        height="20vh"
        sx={{ top: '70vh', position: 'absolute', left: '70vw', maxWidth: '25vw' }}
      >
        <Typography>.</Typography>
      </Skeleton>
    </MHidden>
  </>
);
