export const LOAD_DATA = 'LOAD_DATA';
export const LOAD_DATA_SUCCESSFUL = 'LOAD_DATA_SUCCESSFUL';
export const LOAD_DATA_FAILED = 'LOAD_DATA_FAILED';

export function LoadExampleData() {
  return {
    type: LOAD_DATA
  };
}

export function LoadExampleDataSuccessful(data) {
  return {
    type: LOAD_DATA_SUCCESSFUL,
    payload: data
  };
}

export function LoadExampleDataFailed(errMsg) {
  return {
    type: LOAD_DATA_FAILED,
    errorMessage: errMsg
  };
}
