import {
  GET_USER,
  GET_USER_FAILED,
  GET_USER_SUCCESSFULLY,
  UPDATE_CURRENT_USER,
  UPDATE_CURRENT_USER_FAILED,
  UPDATE_CURRENT_USER_SUCCESSFULLY
} from './user.action';

const initState = {
  data: {
    current: null,
    list: null
  },
  needSignup: false,
  loading: true,
  errorMessage: ''
};

export function userReducer(state = initState, action) {
  switch (action.type) {
    case GET_USER:
    case UPDATE_CURRENT_USER:
      return { ...state, loading: true };
    case GET_USER_SUCCESSFULLY:
    case UPDATE_CURRENT_USER_SUCCESSFULLY:
      return {
        ...state,
        data: { current: action.payload, list: state.data.list },
        loading: false,
        needSignup: false
      };
    case GET_USER_FAILED:
    case UPDATE_CURRENT_USER_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
        loading: false,
        needSignup: true
      };
    default:
      return state;
  }
}
