import cogoToast from 'cogo-toast';

// const option: Partial<{
//   hideAfter: number,
//   position:
//     | 'top-left'
//     | 'top-center'
//     | 'top-right'
//     | 'bottom-left'
//     | 'bottom-center'
//     | 'bottom-right',
//   heading: string,
//   role: string,
//   toastContainerID: string,
//   renderIcon: Function,
//   bar: Partial<{
//     size: string,
//     style: 'solid' | 'dashed' | 'dotted',
//     color: string
//   }>
// }> = {
//   position: 'top-right'
// };

const option = { position: 'top-right', hideAfter: 5 };

export const showSuccess = (msg) => {
  cogoToast.success(msg, option);
};

export const showError = (msg) => {
  cogoToast.error(msg, option);
};

export const showWarning = (msg) => {
  cogoToast.warn(msg, option);
};
