import { Container, MenuItem, Stack, TextField, Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { LoadingButton } from '@material-ui/lab';
import { Form, FormikProvider, useFormik } from 'formik';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Page from 'src/components/Page';
import { GetLessonAll } from 'src/store/lesson/lesson.action';
import { UnlockPayment } from 'src/store/payment/payment.action';
import * as Yup from 'yup';

const RootStyle = styled(Page)(({ theme }) => ({
  minHeight: '100%',
  marginInline: 5,
  paddingTop: '70px',
  paddingBottom: theme.spacing(5)
}));

// ---------------------------------------------------------------------
export default function UnlockPaymentPage() {
  const lessonState = useSelector((state) => state.lessons);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetLessonAll());
  }, []);

  const UnlockSchema = Yup.object().shape({
    lessonId: Yup.string().required('Vui lòng chọn khoá học'),
    email: Yup.string().email('Email không hợp lệ').required('Vui lòng nhập email'),
    bankNumber: Yup.string()
      .min(5, 'Mã giao dịch không hợp lệ')
      .required('Vui lòng nhập mã giao dịch')
  });

  const formik = useFormik({
    initialValues: {
      lessonId: '',
      email: '',
      bankNumber: ''
    },
    validationSchema: UnlockSchema,
    enableReinitialize: true,
    onSubmit: () => {
      dispatch(
        UnlockPayment({
          lessonId: values.lessonId,
          email: values.email,
          bankNumber: values.bankNumber
        })
      );
      resetForm();
    }
  });

  const {
    values,
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    handleChange,
    isSubmitting,
    setSubmitting,
    setFieldValue,
    resetForm,
    dirty
  } = formik;

  return (
    <RootStyle title="The Forum Education | Xác nhận thanh toán">
      <Container maxWidth="lg">
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate>
            <Stack spacing={3}>
              <Typography variant="h4">Mở khóa học cho khách hàng</Typography>
              <TextField
                fullWidth
                autoComplete="email"
                type="email"
                {...getFieldProps('email')}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
                label="Email khách hàng"
                placeholder="Email khách hàng"
              />
              <TextField
                fullWidth
                select
                label="Chọn khóa học cần mở khóa"
                labelId="select-lesson"
                onChange={handleChange}
                value={values.lessonId}
                {...getFieldProps('lessonId')}
                defaultValue=""
                error={Boolean(touched.lessonId && errors.lessonId)}
              >
                <MenuItem value="" disabled>
                  Chọn khóa học
                </MenuItem>
                {lessonState.data.list &&
                  lessonState.data.list.map((lesson) => (
                    <MenuItem key={`${lesson._id}`} value={lesson._id}>
                      {lesson.name}
                    </MenuItem>
                  ))}
              </TextField>
              <TextField
                fullWidth
                autoComplete="bankNumber"
                type="text"
                {...getFieldProps('bankNumber')}
                error={Boolean(touched.bankNumber && errors.bankNumber)}
                helperText={touched.bankNumber && errors.bankNumber}
                label="Mã giao dịch khi đối soát báo có, VD: FTxxxxxx"
                placeholder="Mã giao dịch khi đối soát báo có, VD: FTxxxxxx"
              />
              <LoadingButton fullWidth variant="contained" type="submit" loading={isSubmitting}>
                Mở khóa
              </LoadingButton>
            </Stack>
          </Form>
        </FormikProvider>
      </Container>
    </RootStyle>
  );
}
