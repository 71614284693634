import { Stack } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  SubmitSpeakingAssignment,
  SubmitWritingAssignment
} from 'src/components/assignment/SubmitAssignment';
import { GetAllLessonAssignment } from 'src/store/lesson-assignment/lesson-assignment.action';

export const CourseSubmitWrapper = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const previousAssignment = useSelector((state) => state.assignment);
  const userState = useSelector((state) => state.user);
  const lessonState = useSelector((state) => state.lessons);

  useEffect(() => {
    if (lessonState.data.selected) dispatch(GetAllLessonAssignment(lessonState.data.selected._id));
  }, [lessonState]);

  useEffect(() => {
    setLoading(previousAssignment.loading);
  }, [previousAssignment]);

  return (
    <Stack
      direction={{ xs: 'column', lg: 'row' }}
      justifyContent="space-around"
      alignItems={{ xs: 'center', lg: 'start' }}
      spacing={5}
    >
      <Stack sx={{ maxWidth: '80vw' }}>
        <SubmitWritingAssignment
          previousAssignment={previousAssignment.assignments ? previousAssignment.assignments : []}
          loading={loading}
          lessonId={lessonState.data.selected?._id}
          lessonName={lessonState.data.selected?.name}
          userState={userState}
        />
      </Stack>
      <Stack sx={{ maxWidth: '80vw' }}>
        <SubmitSpeakingAssignment
          previousAssignment={previousAssignment.assignments ? previousAssignment.assignments : []}
          loading={loading}
          lessonId={lessonState.data.selected?._id}
          lessonName={lessonState.data.selected?.name}
          userState={userState}
        />
      </Stack>
    </Stack>
  );
};
