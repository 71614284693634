import { Alert, Box, Card, Container, Link, Typography } from '@material-ui/core';
// material
import { styled } from '@material-ui/core/styles';
// layouts
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { ForgetPassword } from 'src/components/authentication/forgetpassword';
import { MHidden } from '../../components/@material-extend';
import AuthSocial from '../../components/authentication/AuthSocial';
import { RegisterForm } from '../../components/authentication/register';
// components
import Page from '../../components/Page';
import AuthLayout from '../../layouts/AuthLayout';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Register() {
  const auth = useSelector((state) => state.auth);
  const [error, setError] = useState();

  useEffect(() => {
    if (auth.errorMessage) {
      setError(auth.errorMessage.code);
    }
  }, [auth.errorMessage]);

  return (
    <RootStyle title="The Forum Education | Đăng ký tài khoản">
      <AuthLayout>
        Bạn đã có tài khoản? &nbsp;
        <Link underline="none" variant="subtitle2" component={RouterLink} to="/login">
          Đăng nhập
        </Link>
      </AuthLayout>

      <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: 5, mb: 5 }}>
            Quản lý khoá học dễ dàng, chất lượng khoá học đảm bảo!
          </Typography>
          <img alt="register" src="/static/illustrations/illustration_register.png" />
        </SectionStyle>
      </MHidden>

      <Container>
        <ContentStyle>
          <Box sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              The Forum Education - Cung cấp khoá học IELTS & SAT chất lượng nhất
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              Mua một lần, học mãi mãi. Dễ dàng chuyển đổi khoá học khi học tại Trung tâm!
            </Typography>
          </Box>

          <AuthSocial />

          {error && (
            <Alert severity="error" sx={{ mb: 5 }}>
              {error}
            </Alert>
          )}
          <RegisterForm error={error} setError={setError} />

          <Typography variant="body2" align="left" sx={{ color: 'text.secondary', mt: 3 }}>
            Với việc Đăng ký tài khoản, tôi đồng ý với{' '}
            <Link underline="always" sx={{ color: 'text.primary' }} href="/dieu-khoan-dich-vu">
              Điều khoản dịch vụ
            </Link>{' '}
            và{' '}
            <Link underline="always" sx={{ color: 'text.primary' }} href="/chinh-sach-bao-mat">
              Chính sách bảo mật thông tin
            </Link>
            .
          </Typography>

          <MHidden width="smUp">
            <Typography variant="subtitle2" sx={{ mt: 3, textAlign: 'center' }}>
              Bạn đã có tài khoản? &nbsp;
              <Link to="/login" component={RouterLink}>
                Đăng nhập
              </Link>
            </Typography>
          </MHidden>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
