import { Link as RouterLink } from 'react-router-dom';
import React from 'react';
import { Button, Typography } from '@material-ui/core';
import { UrlConfigs } from 'src/utils/UrlConfig';

const NoLesson = () => (
  <>
    <Typography variant="body2" gutterBottom>
      Chưa có khóa học nào ở đây, bạn có thể chọn học thử và mua khóa học nhé!
    </Typography>

    <Button
      LinkComponent={RouterLink}
      to={UrlConfigs.COURSES}
      fullWidth
      size="large"
      variant="outlined"
      sx={{ maxWidth: '200px', mt: 3 }}
    >
      Các khóa học
    </Button>
  </>
);

export default NoLesson;
