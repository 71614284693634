import { format, formatDistanceToNow } from 'date-fns';
import React from 'react';

// ----------------------------------------------------------------------

export function fDate(date) {
  return format(new Date(date), 'dd MMMM yyyy');
}

export function fDateTime(date) {
  return format(new Date(date), 'dd MMM yyyy HH:mm');
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true
  });
}
export function displayTime(length, isSecond = true) {
  if (!length) return null;
  const hours = Math.floor(length / (60 * 60));
  const minutes = Math.floor(length / 60) % 60;
  const seconds = Math.floor(length % 60);
  return (
    <span>
      {hours > 0
        ? `${hours.toLocaleString('en-US', {
            minimumIntegerDigits: 1,
            useGrouping: false
          })}  giờ `
        : ''}
      {minutes.toLocaleString('en-US', {
        minimumIntegerDigits: 1,
        useGrouping: false
      })}{' '}
      phút{' '}
      {isSecond
        ? `${seconds.toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
          })} giây`
        : ''}
    </span>
  );
}

export const displayYoutubeTime = (length) => {
  const hours = Math.floor(length / (60 * 60));
  const minutes = Math.floor(length / 60) % 60;
  const seconds = Math.floor(length % 60);
  return `${
    hours > 0
      ? `${hours.toLocaleString('en-US', {
          minimumIntegerDigits: 1,
          useGrouping: false
        })}:`
      : ''
  }
        ${minutes.toLocaleString('en-US', {
          minimumIntegerDigits: 1,
          useGrouping: false
        })}
      :
      ${seconds.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
      })}
    `.replace(/\s/g, '');
};
