import { Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { ToggleDialogSupport } from 'src/store/dialogs/dialog.action';
import { UrlConfigs } from 'src/utils/UrlConfig';
import SupportSectionForm from './SupportSectionForm';

const SupportDialog = () => {
  const [sent, setSent] = useState(false);

  const supportDialog = useSelector((state) => state.dialogs.supportDialog);

  const dispatch = useDispatch();

  const handleSetOpen = () => {
    dispatch(ToggleDialogSupport());
  };

  if (sent) return <Navigate replace to={UrlConfigs.THANKYOU_SUPPORT} />;

  return (
    <Dialog fullWidth onClose={handleSetOpen} open={supportDialog.open}>
      <DialogContent>
        <SupportSectionForm sx={{ pb: 5 }} setSent={setSent} sent={sent} />
      </DialogContent>
    </Dialog>
  );
};

export default SupportDialog;
