import { ofType } from 'redux-observable';
import { catchError, map, switchMap, takeUntil, of } from 'rxjs';
import { getAllCategory } from 'src/apis/category.api';
import {
  GetCategoriesFailed,
  GetCategoriesSuccessful,
  GET_CATEGORIES,
  GET_CATEGORIES_FAILED
} from './category.action';

export const getCategoriesEpic = (_action$) =>
  _action$.pipe(
    ofType(GET_CATEGORIES),
    switchMap(() =>
      getAllCategory().pipe(
        map((res) => GetCategoriesSuccessful(res.data)),
        takeUntil(_action$.pipe(ofType(GET_CATEGORIES_FAILED))),
        catchError((err) => {
          console.log('err', err);
          return of(GetCategoriesFailed(err));
        })
      )
    )
  );
