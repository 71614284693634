/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography
} from '@material-ui/core';
import { styled } from '@material-ui/styles';
import { Form, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { DISCOUNT, LESSON_PAYMENT_PAID, LESSON_PAYMENT_PARENT_PAID } from 'src/constants/constant';
import { ThumbnailStyled } from 'src/layouts/Searchbar';
import { SetContentDialogCod, ToggleDialogCod } from 'src/store/dialogs/dialog.action';
import { GetLessonBySeo } from 'src/store/lesson/lesson.action';
import { CheckoutPayment, VerifyLesson } from 'src/store/payment/payment.action';
import { UrlConfigs } from 'src/utils/UrlConfig';
import { useQuery } from 'src/utils/useQuery';
import * as Yup from 'yup';
import { VerifyPayment } from '../../store/payment/payment.action';
import BankingInfo from './Dialogs/BankingInfo';
import ConfirmBankingDialog from './Dialogs/ConfirmBanking';
import WarningPaid from './Dialogs/WarningPaid';
import { mapPayment, mapPaymentMethod } from './Payment.utils';
import SummaryInfo from './Summary/SummaryInfo';
import VerifiedLessonCallback from './Summary/VerifiedPayment';

// ----------------------------------------------------------------------

const PaymentMethodStyle = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(5),
  paddingRight: theme.spacing(5),
  flex: '0 1 60%'
}));

export const PaymentForm = ({ step, setStep }) => {
  const lessonState = useSelector((state) => state.lessons);
  const auth = useSelector((state) => state.auth);
  const user = useSelector((state) => state.user);
  const paymentState = useSelector((state) => state.payment);
  const couponState = useSelector((state) => state.coupon);
  const [loading, setLoading] = useState(true);
  const [warningPaid, setWarningPaid] = useState(false);
  const [verified, setVerified] = useState(false);
  const [openBanking, setOpenBanking] = useState(false);
  const [confirmBanking, setConfirmBanking] = useState(false);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const query = useQuery();

  const [type, vnpResponseCode, errorCode, resultCode] = [
    query.get('type'),
    query.get('vnp_ResponseCode'),
    query.get('errorCode'),
    query.get('resultCode')
  ];

  const lastIDSelected = localStorage.getItem('last-id-selected');

  const PaymentSchema = Yup.object().shape({
    paymentMethod: Yup.string().required(),
    address: Yup.string(),
    city: Yup.string()
  });

  const formik = useFormik({
    initialValues: {
      paymentMethod: 'ATM Nội Địa',
      discount: '',
      address: '',
      city: ''
    },
    validationSchema: PaymentSchema,
    enableReinitialize: true,

    onSubmit: () => {
      if (!auth.isAuthorized) {
        // dispatch(HandleLoginPopup());
        navigate(UrlConfigs.REGISTER);
        setSubmitting(false);
        return;
      }
      if (values.paymentMethod === 'Chuyển khoản') {
        setOpenBanking(true);
      }

      if (values.paymentMethod === 'COD') {
        dispatch(
          SetContentDialogCod({
            ...(user.data.current
              ? {
                  name: `${user.data.current.firstName} ${user.data.current.lastName}`,
                  phone: user.data.current.phone,
                  course: lessonState.data.selected.name
                }
              : { name: '', phone: '', course: '' })
          })
        );
        dispatch(ToggleDialogCod());
      }
    }
  });

  const { values, getFieldProps, isSubmitting, setSubmitting, touched, errors } = formik;

  const CancelBanking = () => {
    setSubmitting(false);
    setOpenBanking(false);
  };

  const ConfirmBanking = () => {
    setSubmitting(true);
    setConfirmBanking(true);
    setOpenBanking(false);
  };

  useEffect(() => {
    if (type && !paymentState.data) {
      let paramJoin = '';
      query.forEach((val, key) => {
        paramJoin = paramJoin.concat(`${key}=${val}&`);
      });
      dispatch(VerifyPayment(paramJoin));
      return;
    }
    if (lessonState && !lessonState.data.selected && lastIDSelected) {
      dispatch(GetLessonBySeo(lastIDSelected));
    }
  }, []);

  useEffect(() => {
    if (lessonState.data.selected) setLoading(lessonState.loading);

    if (
      [LESSON_PAYMENT_PARENT_PAID, LESSON_PAYMENT_PAID].includes(paymentState.lessonPaid) &&
      !verified
    ) {
      setWarningPaid(true);
    }
  }, [lessonState, paymentState, verified]);

  useEffect(() => {
    if (isSubmitting && auth.isAuthorized) {
      if (
        values.paymentMethod === 'COD' ||
        (values.paymentMethod === 'Chuyển khoản' && !confirmBanking)
      )
        return;
      const method = mapPayment(values.paymentMethod);
      const lastPrice = Math.round(
        lessonState.data.selected.price *
          (1 - DISCOUNT / 100) *
          (1 - (couponState.selected ? couponState.selected.percent / 100 : 0))
      );
      // if (lastPrice === 0) {
      //   window.alert('WTF FREE');
      //   return;
      // }

      const request = {
        lessonId: lessonState.data.selected._id,
        price: lastPrice,
        ...(method === 'ALEPAY' ? { methodCode: mapPaymentMethod(values.paymentMethod) } : null),
        address: values.address ? values.address : 'Không có',
        city: values.city ? values.city : 'Không có',
        country: 'Việt nam',
        ...(method === 'VNPAY' ? { bankCode: mapPaymentMethod(values.paymentMethod) } : null),
        ...(method === 'ALEPAY' ? { checkoutType: 4 } : null),
        method: method
      };
      dispatch(CheckoutPayment(request));
    }
    if (auth.isAuthorized) dispatch(VerifyLesson(lastIDSelected));
  }, [isSubmitting, auth, confirmBanking]);

  useEffect(() => {
    if (paymentState.data && paymentState.data.checkoutUrl) {
      window.location.replace(paymentState.data.checkoutUrl);
    }

    if (paymentState.verified) {
      setLoading(false);
      setVerified(true);
      setStep(2);
    }
  }, [paymentState]);

  if (lessonState && !lessonState.data.selected) {
    if (!lastIDSelected) return <Navigate to={UrlConfigs.COURSES} />;
  }

  if (warningPaid) return <WarningPaid />;

  if (openBanking)
    return (
      <BankingInfo
        currentUser={user.data.current}
        lessonState={lessonState}
        cancel={CancelBanking}
        confirm={ConfirmBanking}
      />
    );

  if (confirmBanking) return <ConfirmBankingDialog />;

  return (
    !loading && (
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate>
          {!verified ? (
            <Stack direction={{ xs: 'column', lg: 'row' }} justifyContent="space-evenly">
              <PaymentMethodStyle>
                <Stack sx={{ mb: 8 }}>
                  <Typography variant="h4" gutterBottom>
                    Chọn phương thức thanh toán
                  </Typography>
                </Stack>
                <Stack spacing={3}>
                  <FormControl component="fieldset">
                    <RadioGroup defaultValue={values.pick} {...getFieldProps('paymentMethod')}>
                      <FormControlLabel
                        labelPlacement="start"
                        sx={{ mb: 8 }}
                        control={<Radio />}
                        label={
                          <Stack direction="row" justifyContent="left" flexGrow={1}>
                            <ThumbnailStyled
                              sx={{ mr: 4, maxWidth: '80px' }}
                              alt="COD Payment"
                              src="/static/icons/cod.png"
                            />
                            <Box maxWidth="400px">
                              <Typography variant="h5">
                                COD - Thanh toán khi nhận khóa học
                              </Typography>
                              <Typography variant="body1">
                                Nhận Code mở khóa học tại nhà sau 2-5 ngày qua thanh toán COD, thu
                                tiền khi giao hàng.
                              </Typography>
                            </Box>
                          </Stack>
                        }
                        value="COD"
                        name="paymentMethod"
                      />
                      <FormControlLabel
                        labelPlacement="start"
                        sx={{ mb: 8 }}
                        control={<Radio />}
                        label={
                          <Stack direction="row" justifyContent="left" flexGrow={1}>
                            <ThumbnailStyled
                              sx={{ mr: 4, maxWidth: '80px' }}
                              alt="ATM Payment"
                              src="/static/icons/atm.svg"
                            />
                            <Box maxWidth="400px">
                              <Typography variant="h5">Thẻ ATM Nội địa</Typography>
                              <Typography variant="body1">
                                Hỗ trợ thẻ ATM Nội địa hầu hết các ngân hàng Việt Nam: Vietcombank,
                                BIDV, VIB, Techcombank,...
                              </Typography>
                            </Box>
                          </Stack>
                        }
                        value="ATM Nội Địa"
                        name="paymentMethod"
                      />
                      <FormControlLabel
                        labelPlacement="start"
                        sx={{ mb: 8 }}
                        control={<Radio />}
                        label={
                          <Stack direction="row" justifyContent="left" flexGrow={1}>
                            <ThumbnailStyled
                              sx={{ mr: 4, maxWidth: '80px' }}
                              alt="ATM Payment"
                              src="/static/icons/atm-quocte.svg"
                            />
                            <Box maxWidth="400px">
                              <Typography variant="h5">Thẻ Quốc tế</Typography>
                              <Typography variant="body1">
                                Hỗ trợ các thẻ thanh toán Quốc Tế: VISA, MasterCard, American
                                Express, JCB,...
                              </Typography>
                            </Box>
                          </Stack>
                        }
                        value="ATM Quốc tế"
                        name="paymentMethod"
                      />
                      <FormControlLabel
                        labelPlacement="start"
                        sx={{ mb: 8 }}
                        control={<Radio />}
                        label={
                          <Stack direction="row" justifyContent="left">
                            <ThumbnailStyled
                              sx={{ mr: 4, maxWidth: '80px' }}
                              alt="MOMO Payment"
                              src="/static/icons/momo.png"
                            />
                            <Box maxWidth="400px">
                              <Typography variant="h5">Ví MoMo</Typography>
                              <Typography variant="body1">
                                Thanh toán nhanh bằng mã QR của MoMo, Sử dụng App MoMo hoặc ứng dụng
                                camera hỗ trợ QR code để quét mã.
                              </Typography>
                            </Box>
                          </Stack>
                        }
                        value="MOMO"
                        name="paymentMethod"
                      />
                      <FormControlLabel
                        labelPlacement="start"
                        sx={{ mb: 8 }}
                        control={<Radio />}
                        label={
                          <Stack direction="row" justifyContent="left">
                            <ThumbnailStyled
                              sx={{ mr: 4, maxWidth: '80px' }}
                              alt="QR CODE Payment"
                              src="/static/icons/paytype_qr.png"
                            />
                            <Box maxWidth="400px">
                              <Typography variant="h5">QR Code Ngân hàng</Typography>
                              <Typography variant="body1">
                                Thanh toán nhanh bằng mã QR thông qua ví điện tử của ngân hàng, hỗ
                                trợ nhiều ngân hàng: Vietcombank, VietinBank, BIDV, Agribank,...
                              </Typography>
                            </Box>
                          </Stack>
                        }
                        value="QR CODE"
                        name="paymentMethod"
                      />
                      <FormControlLabel
                        labelPlacement="start"
                        sx={{ mb: 8 }}
                        control={<Radio />}
                        label={
                          <Stack direction="row" justifyContent="left">
                            <ThumbnailStyled
                              sx={{ mr: 4, maxWidth: '80px' }}
                              alt="E-WALLET Payment"
                              src="/static/icons/qr-payment.svg"
                            />
                            <Box maxWidth="400px">
                              <Typography variant="h5">Ví điện tử</Typography>
                              <Typography variant="body1">
                                Thanh toán nhanh bằng mã QR, hỗ trợ nhiều ví điện tử: ví MoMo,
                                Shoppee Pay, VinID..
                              </Typography>
                            </Box>
                          </Stack>
                        }
                        value="EWALLET"
                        name="paymentMethod"
                      />
                      <FormControlLabel
                        labelPlacement="start"
                        sx={{ mb: 8 }}
                        control={<Radio />}
                        label={
                          <Stack direction="row" justifyContent="left" flexGrow={1}>
                            <ThumbnailStyled
                              sx={{ mr: 4, maxWidth: '80px' }}
                              alt="Chuyển khoản"
                              src="/static/icons/ib.svg"
                            />
                            <Box maxWidth="400px">
                              <Typography variant="h5">Chuyển khoản ngân hàng</Typography>
                              <Typography variant="body1">
                                Chuyển khoản trực tiếp đến The Forum, nhận khóa học ngay khi xác
                                nhận thanh toán thành công.
                              </Typography>
                            </Box>
                          </Stack>
                        }
                        value="Chuyển khoản"
                        name="paymentMethod"
                      />
                    </RadioGroup>
                  </FormControl>
                  <Divider />
                  <Typography>Thông tin thêm (không bắt buộc)</Typography>
                  <TextField
                    fullWidth
                    autoComplete="address"
                    type="address"
                    label="Địa chỉ"
                    {...getFieldProps('address')}
                    error={Boolean(touched.address && errors.address)}
                    helperText={touched.address && errors.address}
                  />
                  <TextField
                    fullWidth
                    autoComplete="city"
                    type="text"
                    label="Thành phố/tỉnh"
                    {...getFieldProps('city')}
                    error={Boolean(touched.city && errors.city)}
                    helperText={touched.city && errors.city}
                  />
                </Stack>
              </PaymentMethodStyle>
              <SummaryInfo
                currentUser={user.data.current}
                lesson={lessonState.data.selected}
                coupon={couponState}
                openLoginPopup={auth.openLoginPopup}
                isAuthorized={auth.isAuthorized}
                isSubmitting={isSubmitting}
                values={values}
              />
            </Stack>
          ) : (
            <VerifiedLessonCallback
              type={type}
              vnpResponseCode={vnpResponseCode}
              errorCode={errorCode}
              resultCode={resultCode}
            />
          )}
        </Form>
      </FormikProvider>
    )
  );
};
