import * as React from 'react';
import { styled } from '@material-ui/styles';
// Import Swiper React components
import SwiperCore, { Navigation } from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';
import { reviews } from 'src/_mocks_/reviews';
import { Avatar, Box, Container, Typography } from '@material-ui/core';

const RootStyle = styled(Swiper)({
  '& .swiper-button-next': {
    color: 'grey'
  },
  '& .swiper-button-next:after': {
    fontSize: '25px'
  },
  '& .swiper-button-prev': {
    color: 'grey'
  },
  '& .swiper-button-prev:after': {
    fontSize: '25px'
  },
  '& .swiper-wrapper': {
    alignItems: 'center'
  },
  paddingInline: '70px'
});

const AvatarStyle = styled(Avatar)(({ theme }) => ({
  zIndex: 9,
  width: 64,
  height: 64,
  margin: '20px auto 0px'
}));

export default function ReviewSection() {
  SwiperCore.use([Navigation]);

  return (
    <div>
      <Typography variant="h2" textAlign="center">
        Đánh giá về The Forum
      </Typography>
      <RootStyle
        spaceBetween={80}
        slidesPerView={window.matchMedia('(max-device-width: 960px)').matches ? 1 : 3}
        navigation
      >
        {reviews.map((review, idx) => (
          <SwiperSlide key={`swiper-${idx}`}>
            <Box textAlign="center">
              <Typography>"{review.content}"</Typography>

              <AvatarStyle
                alt={`Review The Forum Education - ${review.name}`}
                src={`static/avatar/avt-review${idx + 1}.png`}
              />

              <Typography>
                <strong> {review.name} </strong>
                <br /> thông qua đánh giá từ {review.from}
              </Typography>
            </Box>
          </SwiperSlide>
        ))}

        {/* <SwiperSlide>
          <Box textAlign="center">
            <Typography>
              "Các anh chị giáo viên vừa dễ thương, tận tâm lại có trình độ IELTS cao, nên mình tiến
              bộ rất nhanh. Cám ơn The Forum Center!"
            </Typography>
            <br />
            <Typography>
              <strong> Hạ Đan </strong>
              <br /> thông qua đánh giá từ Facebook
            </Typography>
          </Box>
        </SwiperSlide>
        <SwiperSlide>
          <Box textAlign="center">
            <Typography>
              "Mọi người nên học ở đây thay vì những trung tâm ielts khác mình đã từng học bởi ở đây
              mọi người sẽ được tiếp cận với một cách học mới đầy thú vị với một timeline rất thoải
              mái với tất cả mọi người kể cả người bận rộn cùng tiếp xúc với 1 dàn giáo viên chuyên
              nghiệp nhưng rất vui tính và thoải mái. Một nơi rất tốt để phát triển các kĩ năng của
              bạn!"
            </Typography>
            <br />
            <Typography>
              <strong>- Hoàng Quân </strong>
              <br /> thông qua đánh giá từ Facebook
            </Typography>
          </Box>
        </SwiperSlide>
        <SwiperSlide>
          <Box textAlign="center">
            <Typography>
              "Mình là học viên của trung tâm, các thầy cô rất nhiệt tình tình. Theo mình đánh giá
              đây là nơi học IELTS tốt nhất Vũng Tàu."
            </Typography>
            <br />
            <Typography>
              <strong> Nguyen Pham </strong>
              <br /> thông qua đánh giá từ Facebook
            </Typography>
          </Box>
        </SwiperSlide> */}
      </RootStyle>
    </div>
  );
}
