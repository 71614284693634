import * as React from 'react';
import { styled } from '@material-ui/styles';
import { Grid, Typography, CardMedia } from '@material-ui/core';

export default function WhyLearningSection() {
  return (
    <>
      <Typography variant="h2" pt={5} pb={5} textAlign="center" data-aos="fade-down">
        Lý do để chọn The Forum
      </Typography>
      <Grid container spacing={3} sx={{ background: '#fffafa' }} padding={3} data-aos="fade-down">
        <Grid item sm={12} md={6} lg={3}>
          <CardMedia
            component="img"
            alt="The Forum Course"
            image="/static/lydo/lydo1.jpg"
            sx={{ width: '40%', maxWidth: '30vw', margin: '0 auto' }}
          />
          <Typography variant="h4" textAlign="center" py={2}>
            Phương pháp hiệu quả
          </Typography>
          <Typography textAlign="center">
            Tự hào là 1 trong những trung tâm Anh ngữ có uy tín và chất lượng hàng đầu với phương
            pháp luyện thi IELTS độc quyền, khoa học, sáng tạo. The Forum luôn hướng đến việc tạo
            môi trường học năng động, tích cực và tạo cảm hứng giúp bạn hoàn toàn tự tin bước vào kì
            thi thật.
          </Typography>
        </Grid>
        <Grid item sm={12} md={6} lg={3}>
          <CardMedia
            component="img"
            alt="The Forum Course"
            image="/static/lydo/lydo2.jpg"
            sx={{ width: '40%', maxWidth: '30vw', margin: '0 auto' }}
          />
          <Typography variant="h4" textAlign="center" py={2}>
            Giảng viên kinh nghiệm
          </Typography>
          <Typography textAlign="center">
            The Forum luôn chú trọng đến chất lượng đào tạo, các giáo viên tại trung tâm bắt buộc sở
            hữu IELTS ít nhất 7.5 và kỹ năng giảng dạy ở mức IELTS 8.0+ hoặc trình độ sư phạm, thạc
            sỹ. Đặc biệt, mỗi giáo viên chỉ giảng dạy kỹ năng mà mình giỏi nhất, đồng nghĩa với việc
            học sinh sẽ học với người giỏi nhất, bảo đảm truyền đạt kiến thức hiệu quả. Giáo viên
            không chỉ có nhiệm vụ giảng dạy, mà còn là bạn đồng hành cùng học sinh trên chặng đường
            đào tạo.
          </Typography>
        </Grid>
        <Grid item sm={12} md={6} lg={3}>
          <CardMedia
            component="img"
            alt="The Forum Course"
            image="/static/lydo/lydo3.jpg"
            sx={{ width: '40%', maxWidth: '30vw', margin: '0 auto' }}
          />
          <Typography variant="h4" textAlign="center" py={2}>
            Hệ thống thi thử Online
          </Typography>
          <Typography textAlign="center">
            The Forum hiện tại đang là đối tác Vàng của Hội Đồng Anh. Nắm bắt được xu hướng thi
            IELTS trên máy tính hiện nay, The Forum đã xây dựng nền tảng công nghệ mô phỏng hình
            thức thi này nhằm giúp các học viên được tiếp xúc cũng như trải nghiệm tốt nhất trong
            suốt quá trình tham gia khóa học.
          </Typography>
        </Grid>
        <Grid item sm={12} md={6} lg={3}>
          <CardMedia
            component="img"
            alt="The Forum Course"
            image="/static/lydo/lydo4.jpg"
            sx={{ width: '40%', maxWidth: '30vw', margin: '0 auto' }}
          />
          <Typography variant="h4" textAlign="center" py={2}>
            Đội ngũ hỗ trợ
          </Typography>
          <Typography textAlign="center">
            Không chỉ được đánh giá là một trong những trung tâm luyện thi IELTS hàng đầu, chính
            sách chăm sóc hỗ trợ học viên tại The Forum sẽ mang lại cho bạn sự hài lòng nhất. Mọi ý
            kiến và mong muốn của bạn luôn luôn được lắng nghe, tiếp thu và giải quyết nhanh chóng
            bởi đội ngũ chăm sóc chuyên nghiệp và Trợ giảng đồng hành trong suốt quá trình học tập.
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}
