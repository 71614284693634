import { TRIM_VIDEO_REGEX } from 'src/constants/constant';

export const trimVideoTitle = (title) => {
  const matchArray = title.match(TRIM_VIDEO_REGEX);
  if (!matchArray) return title;
  const MATCH_TITLE_INDEX = matchArray.length - 2;
  return matchArray[MATCH_TITLE_INDEX].trim();
};

export const trimTopicTitle = (topic) => topic.slice(topic.indexOf('. ') + 1);

export const makeVideoSrc = (video) => `${video.id}/${video.security_token}`;

export const makeVideoSrcYoutube = (video) => `${video.id}`;

export function sortVideoTitle(a, b) {
  if (!a.title.match(/^\d+/) || !b.title.match(/^\d+/)) return 0;
  return +a.title.match(/^\d+/)[0] - +b.title.match(/^\d+/)[0];
}
