import { Tab, Tabs, Typography } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { removeAscent } from 'src/utils/removeascent';
import Page from '../Page';

const RootStyle = styled(Page)(({ theme }) => ({}));

const AvatarDivStyle = styled('div')(({ theme }) => ({
  '& img': {
    margin: '0 auto',
    borderRadius: '50%',
    width: '100px'
  }
}));
const TabDivStyle = styled('div')(({ theme }) => ({
  marginTop: 15
}));

function a11yProps(key) {
  return {
    id: key,
    'aria-controls': `vertical-tabpanel-${key}`
  };
}

const tabConfig = [
  {
    label: 'Thông tin cá nhân',
    key: 'tab-info'
  },
  // {
  //   label: 'Ảnh đại diện',
  //   key: 'tab-avatar'
  // },
  {
    label: 'Mật khẩu',
    key: 'tab-password'
  }
];

const LeftProfile = (props) => {
  const [user, setUser] = useState(props.user);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    props.onChange && props.onChange(event.target.id);
    setValue(newValue);
  };
  useEffect(() => {}, []);
  return (
    <RootStyle>
      <AvatarDivStyle>
        <img
          alt=""
          src={
            (props.picture && props.picture.photoURL) ||
            `https://ui-avatars.com/api/?name=${user.firstName}+${user.lastName.split(':')[0]}`
          }
        />
        <Typography
          gutterBottom
          align="center"
          variant="subtitle1"
          style={{ marginTop: 15, fontWeight: 'bold', fontSize: 18 }}
        >
          {removeAscent(`${user.firstName} ${user.lastName}`)}
        </Typography>
      </AvatarDivStyle>
      <TabDivStyle>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: 'divider' }}
        >
          {tabConfig.map((tab, idx) => (
            <Tab key={tab.key} label={tab.label} {...a11yProps(tab.key)} />
          ))}
        </Tabs>
      </TabDivStyle>
    </RootStyle>
  );
};

LeftProfile.propTypes = { user: PropTypes.object.isRequired, onChange: PropTypes.func };

export default LeftProfile;
