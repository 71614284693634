import { GET_COUPON, GET_COUPON_FAILED, GET_COUPON_SUCCESSFULLY } from './coupon.action';

const initState = {
  selected: null,
  loading: true,
  errorMessage: ''
};

export function couponReducer(state = initState, action) {
  switch (action.type) {
    case GET_COUPON:
      return initState;
    case GET_COUPON_SUCCESSFULLY:
      return {
        ...state,
        loading: false,
        selected: action.payload
      };
    case GET_COUPON_FAILED:
      return { selected: null, loading: false, errorMessage: action.errorMessage };
    default:
      return state;
  }
}
