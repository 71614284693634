export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const UPDATE_PASSWORD_SUCCESFULLY = 'UPDATE_PASSWORD_SUCCESFULLY';
export const UPDATE_PASSWORD_FAILED = 'UPDATE_PASSWORD_FAILED';

export function UpdatePassword(currentPassword, newPassword) {
  return {
    type: UPDATE_PASSWORD,
    payload: {
      currentPassword,
      newPassword
    }
  };
}

export function UpdatePasswordSuccessful() {
  return {
    type: UPDATE_PASSWORD_SUCCESFULLY
  };
}

export function UpdatePasswordFailed(errMsg) {
  return {
    type: UPDATE_PASSWORD_FAILED,
    errorMessage: errMsg
  };
}
