import { Container, Typography } from '@material-ui/core';
import { RootStyle } from '../Course/CoursePreview/CoursePreview';

export function VanChuyen() {
  return (
    <RootStyle title="The Forum Education | Chính sách vận chuyển">
      <Container sx={{ lineHeight: 2 }}>
        <Typography variant="h3" gutterBottom textAlign="center">
          Chính sách vận chuyển, giao nhận
        </Typography>
        Với tài khoản học online – phương thức giao hàng: Theforum.vn sẽ bàn giao tài khoản đăng
        nhập và mật khẩu đăng nhập lần đầu cho quý khách qua email và số điện thoại theo thông tin
        Quý khách đã đăng ký khi đặt hàng. <br />
        Từ thứ 2 đến thứ 6: Bàn giao tài khoản muộn nhất 24h sau khi thanh toán. <br />
        Thứ 7, Chủ nhật: Bàn giao tài khoản vào ngày thứ 2 tuần tiếp theo. <br />
        Nếu quá thời hạn bàn giao đã cam kết mà bạn vẫn chưa nhận được thông tin về sản phẩm đã đặt,
        bạn vui lòng thông báo tới bộ phận Chăm sóc khách hàng của chúng tôi theo thông tin sau để
        được hỗ trợ kịp thời. <br />
        Với hình thức học tại chi nhánh: lịch học sẽ được các bạn tư vấn viên liên hệ trực tiếp với
        quý khách qua email và số điện thoại theo thông tin Quý khách đã đăng ký. <br />
        Hotline: 1900886640 <br />
        Email: education@theforumcenter.vn
      </Container>
    </RootStyle>
  );
}
