import { Box, Stack, Typography, Link, Button } from '@material-ui/core';
import * as React from 'react';
import { MHidden } from 'src/components/@material-extend';

export default function IntroImage() {
  return (
    <Box
      sx={{
        height: '500px',
        width: '100%',
        mb: {
          xs: '5em',
          sm: '0'
        }
      }}
    >
      <Box
        sx={{
          backgroundImage:
            'url(https://res.cloudinary.com/forum2ea9/image/upload/q_30/v1639636625/images/intro_t3rblo.jpg)',
          backgroundSize: 'contain',
          backgroundPosition: 'inherit',
          backgroundRepeat: 'no-repeat',
          backgroundPositionX: 'center',
          minWidth: '100%',
          minHeight: '100%',
          marginTop: '5em'
        }}
      />
      <Stack
        sx={{
          px: 8,
          py: 3,
          alignSelf: 'center',
          transform: {
            xs: 'translateY(-20em)',
            sm: 'translateY(-15em)',
            md: 'translateY(-10em)',
            xl: 'translateY(-5em)'
          },
          margin: '0 auto',
          backgroundColor: '#fffafa',
          boxShadow: '0px -7px 25px -10px #cacaca',
          width: { md: '100%', xl: '60%' },
          maxWidth: '1200px',
          overflow: 'auto'
        }}
      >
        <Typography variant="h1" gutterBottom textAlign="center">
          Về chúng tôi <br />
        </Typography>
        <Typography
          variant="h6"
          gutterBottom
          maxWidth="65vw"
          alignSelf="center"
          textAlign="center"
          style={{ fontWeight: 600 }}
        >
          The Forum là trung tâm luyện thi IELTS hàng đầu tại Việt Nam theo hai hình thức Trực Tiếp
          và Trực Tuyến. Các khóa học Online của The Forum gồm hàng trăm video được ghi hình sẵn,
          biên soạn bởi các những giáo viên sở hữu IELTS 8.0+, có thể giúp học sinh cải thiện điểm
          mức điểm IELTS nhanh nhất với chi phí thấp nhất.
        </Typography>
        <Link underline="none" href="#courses" sx={{ alignSelf: 'end', pt: 2 }}>
          <Button
            fullWidth
            sx={{ height: '30%', typography: 'h5' }}
            target="_blank"
            variant="outlined"
          >
            Tìm hiểu khóa học
          </Button>
        </Link>
      </Stack>
    </Box>
  );
}
