import { Button, Container, Link, Typography, Stack } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { ToggleDialogSupport } from 'src/store/dialogs/dialog.action';
import { UrlConfigs } from 'src/utils/UrlConfig';
import { RootStyle } from '../Course/CoursePreview/CoursePreview';

export function ThankYouPage() {
  const supportDialog = useSelector((state) => state.dialogs.supportDialog);

  const dispatch = useDispatch();

  if (supportDialog.open) dispatch(ToggleDialogSupport());

  return (
    <RootStyle title="The Forum Education | Cảm ơn đã liên hệ chúng tôi">
      <Container sx={{ lineHeight: 2 }}>
        <Typography variant="h2" gutterBottom textAlign="center">
          The Forum Chân Thành Cảm Ơn!
        </Typography>
        <Typography gutterBottom textAlign="center">
          The Forum đã nhận thông tin tư vấn, chúng tôi sẽ liên hệ bạn sớm nhất!
        </Typography>

        <Stack>
          <Link
            component={RouterLink}
            underline="none"
            variant="subtitle2"
            sx={{ textAlign: 'center', width: '100%' }}
            to={UrlConfigs.COURSES}
            replace
          >
            <Button fullWidth size="large" variant="contained" sx={{ maxWidth: '300px', mt: 5 }} B>
              Về trang chủ
            </Button>
          </Link>
          <Link
            component={RouterLink}
            underline="none"
            variant="subtitle2"
            sx={{ textAlign: 'center', width: '100%' }}
            to={UrlConfigs.ALL_COURSES}
            replace
          >
            <Button fullWidth size="large" variant="outlined" sx={{ maxWidth: '200px', mt: 1 }} B>
              Tất cả khóa học
            </Button>
          </Link>
        </Stack>
      </Container>
    </RootStyle>
  );
}
