import { defer } from 'rxjs';
import http from '../network/http';

export function checkoutPayment(payment) {
  return defer(() => http.post('/v1/payment', payment));
}

export function verifyPayment(paymentParam) {
  return defer(() => http.get(`/v1/payment/verify?${paymentParam}`));
}

export function verifyLesson(lessonID) {
  return defer(() => http.get(`/v1/payment/lesson/${lessonID}`));
}

export function userPayment(uid) {
  return defer(() => http.get(`/v1/payment/user/${uid}`));
}

/**
 *
 * @param {
 *  lessonId,
 *  email
 * } info
 */

export function unlockPayment(info) {
  return defer(() => http.post(`/v1/payment/unlock`, info));
}
