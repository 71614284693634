import { Alert, Card, Container, Link, Stack, Typography } from '@material-ui/core';
// material
import { styled } from '@material-ui/core/styles';
// layouts
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink, Navigate } from 'react-router-dom';
import { ForgetPassword } from 'src/components/authentication/forgetpassword';
import { UrlConfigs } from 'src/utils/UrlConfig';
import { MHidden } from '../../components/@material-extend';
import AuthSocial from '../../components/authentication/AuthSocial';
import { LoginForm } from '../../components/authentication/login';
// components
import Page from '../../components/Page';
import AuthLayout from '../../layouts/AuthLayout';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Login() {
  const auth = useSelector((state) => state.auth);
  const [error, setError] = useState();
  const [loginForm, setLoginForm] = useState(true); // login or forgetpassword

  useEffect(() => {
    if (auth.errorMessage) {
      setError(auth.errorMessage.code);
    }
    return () => setError();
  }, [auth.errorMessage]);

  if (auth.isAuthorized) {
    return <Navigate to={UrlConfigs.SEOAUTH} />;
  }

  return (
    <RootStyle title="The Forum Education | Đăng nhập">
      <AuthLayout>
        Bạn chưa có tài khoản?&nbsp;
        <Link underline="none" variant="subtitle2" component={RouterLink} to="/register">
          Đăng ký ngay
        </Link>
      </AuthLayout>

      <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h4" sx={{ px: 5, mb: 5 }}>
            Chào mừng bạn quay trở lại The Forum Education
          </Typography>
          <img src="/static/illustrations/illustration_login.png" alt="login" />
        </SectionStyle>
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              Đăng nhập
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              Hãy điền thông tin vào bên dưới nhé!
            </Typography>
          </Stack>
          <AuthSocial />

          {error && (
            <Alert severity="error" sx={{ mb: 5 }}>
              {error}
            </Alert>
          )}

          {loginForm ? (
            <LoginForm error={error} setError={setError} setLoginForm={setLoginForm} />
          ) : (
            <ForgetPassword error={error} setLoginForm={setLoginForm} />
          )}

          <MHidden width="smUp">
            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
              Bạn chưa có tài khoản?&nbsp;
              <Link variant="subtitle2" component={RouterLink} to="/register">
                Đăng ký ngay
              </Link>
            </Typography>
          </MHidden>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
