import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  Stack,
  Typography
} from '@material-ui/core';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { UrlConfigs } from 'src/utils/UrlConfig';
import PropTypes from 'prop-types';

const BankingInfo = ({ lessonState, currentUser, confirm, cancel }) => {
  const onCancel = () => {
    cancel && cancel();
  };

  const onConfirm = () => {
    confirm && confirm();
  };

  return (
    <Dialog open>
      <DialogTitle variant="h4" sx={{ textAlign: 'center', py: 3 }}>
        Thông tin chuyển khoản
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 3, border: 'solid 1px darkred', padding: '1em' }}>
          Chủ tài Khoản: Công ty TNHH The Forum Education Vietnam <br />
          Số Tài Khoản: <strong>003039831 </strong> <br />
          Ngân hàng: <strong>Ngân hàng TMCP Quốc tế Việt Nam (VIB)</strong> – Chi nhánh Vũng Tàu
          <br />
          Nội dung:{' '}
          <strong>
            {currentUser?.email}-{lessonState.data.selected?.name}
          </strong>
        </DialogContentText>
        <DialogContentText sx={{ mb: 5 }} fontStyle="italic">
          Lưu ý: Sau khi chuyển khoản vui lòng thông báo với nhân viên tư vấn qua email:
          education@theforumcenter.vn hoặc gọi tới số: <strong>1900886640</strong> để xác nhận về
          khoản tiền.
        </DialogContentText>

        <Stack spacing={2}>
          <Button fullWidth size="large" variant="contained" onClick={onConfirm}>
            Tôi đã chuyển và liên hệ nhân viên (1900886640)
          </Button>
          <Button
            fullWidth
            size="large"
            variant="outlined"
            sx={{ maxWidth: '200px', mb: 3, alignSelf: 'center' }}
            onClick={onCancel}
          >
            Hủy thanh toán
          </Button>
        </Stack>

        <Typography variant="body2" align="left" sx={{ color: 'text.secondary', my: 3 }}>
          Mọi thắc mắc vui lòng tham khảo{' '}
          <Link
            component={RouterLink}
            underline="always"
            sx={{ color: 'text.primary' }}
            to={UrlConfigs.DAT_HANG}
          >
            Chính sách vận chuyển
          </Link>{' '}
          và{' '}
          <Link
            component={RouterLink}
            underline="always"
            sx={{ color: 'text.primary' }}
            to={UrlConfigs.DAT_HANG}
          >
            Chính sách đặt hàng
          </Link>
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

BankingInfo.propTypes = {
  lessonState: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired
};

export default BankingInfo;
