import { Divider, Input, Typography } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import React, { useState } from 'react';
import Page from '../Page';

const RootStyle = styled(Page)(({ theme }) => ({}));

const PreviewContainer = styled('div')(({ theme }) => ({
  marginTop: 15,
  border: '1px solid #6a6f73',
  boxShadow: '0 2px 0 rgb(255 255 255 / 63%), 0 1px 2px rgb(28 29 31 / 15%) inset',
  padding: 18,
  marginBottom: 20,
  '& img': {
    margin: '0 auto',
    height: '200px'
  }
}));
const InputContainer = styled('div')(({ theme }) => ({
  marginTop: 15
}));
const AvatarInfo = () => {
  const [url, setUrl] = useState(null);
  const handlePreviewFile = (event) => {
    const { files } = event.target;
    if (files.length > 0) {
      const file = files[0];
      console.log('handlePreviewFile ~ file', file);
      setUrl(URL.createObjectURL(file));
    }
  };
  return (
    <RootStyle>
      <Typography component="h3" style={{ fontSize: 18, fontWeight: 'bold' }}>
        Ảnh đại diện
      </Typography>
      <Divider />
      <Typography style={{ marginTop: 10, fontWeight: 'bold' }}>Hình ảnh</Typography>
      <PreviewContainer>
        <img alt="" src={url || 'https://img-c.udemycdn.com/user/200_H/anonymous_3.png'} />
      </PreviewContainer>
      <Typography style={{ marginTop: 15, fontWeight: 'bold' }}>Chọn ảnh</Typography>
      <InputContainer>
        <Input type="file" onChange={handlePreviewFile} />
      </InputContainer>
    </RootStyle>
  );
};

export default AvatarInfo;
