import { ACCESS_TOKEN } from 'src/constants/constant';
import {
  LOGIN,
  LOGIN_FAILED,
  LOGIN_SUCCESSFULLY,
  TOGGLE_LOGIN_POPUP
} from './signin/signin.action';
import { SIGN_OUT, SIGN_OUT_SUCCESSFULLY } from './signout/signout.action';
import {
  SIGNUP,
  SIGNUP_FAILED,
  SIGNUP_GOOGLE_SUCCESFULLY,
  SIGNUP_SUCCESFULLY,
  TOGGLE_UPDATE_POPUP
} from './signup/signup.action';

import {
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_SUCCESFULLY,
  UPDATE_PASSWORD_FAILED
} from './updatepassword/updatepassword.action';

const initState = {
  data: null,
  errorMessage: null,
  loading: true,
  loadingSignup: false,
  openLoginPopup: false,
  openUpdatePopup: false,
  userInfo: null,
  isAuthorized: !!localStorage.getItem(ACCESS_TOKEN)
};

export function authReducer(state = initState, action) {
  switch (action.type) {
    case LOGIN:
    case SIGN_OUT:
    case UPDATE_PASSWORD:
      return { ...state, loading: true };
    case UPDATE_PASSWORD_SUCCESFULLY:
      return { ...state, loading: false };
    case SIGN_OUT_SUCCESSFULLY:
      localStorage.removeItem(ACCESS_TOKEN);
      return { ...state, isAuthorized: false };
    case SIGNUP_GOOGLE_SUCCESFULLY:
      return { ...state, loading: false, openUpdatePopup: false };
    case SIGNUP_SUCCESFULLY:
      return {
        ...state,
        loadingSignup: false,
        isAuthorized: true,
        userInfo: action.payload.userInfo
      };
    case LOGIN_SUCCESSFULLY:
      return { ...state, loading: false, isAuthorized: true, userInfo: action.payload.userInfo };
    case TOGGLE_LOGIN_POPUP:
      return { ...state, openLoginPopup: !state.openLoginPopup };
    case TOGGLE_UPDATE_POPUP:
      return { ...state, openUpdatePopup: !state.openUpdatePopup };
    case UPDATE_PASSWORD_FAILED:
    case LOGIN_FAILED:
      return {
        ...state,
        loading: false,
        errorMessage: action.errorMessage
      };
    case SIGNUP:
      return { ...state, loadingSignup: true };
    case SIGNUP_FAILED:
      return { ...state, loading: false, errorMessage: action.errorMessage };
    default:
      return state;
  }
}
