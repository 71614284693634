import { Box, Grid, Link, SvgIcon, Typography } from '@material-ui/core';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

export const AddressWrapper = () => (
  <>
    <Box>
      <Typography sx={{ fontStyle: 'bold' }}>
        <strong>Vũng Tàu</strong>
      </Typography>
    </Box>
    <Box>
      <Link
        variant="body"
        underline="none"
        href="https://goo.gl/maps/AiYgtq4mZd5swHba7"
        color="inherit"
      >
        <strong>Hội Sở</strong> : 01G3 Trung Tâm Thương Mại P7 TP Vũng Tàu
      </Link>
    </Box>
    <Box>
      <Link
        variant="body"
        underline="none"
        href="https://goo.gl/maps/YUr3zVmeGwuNiiF87"
        color="inherit"
      >
        <strong>Cơ sở 1</strong>: 27G1 Nguyễn Oanh P7 TP Vũng Tàu
      </Link>
    </Box>
    <Box>
      <Link
        variant="body"
        underline="none"
        href="https://goo.gl/maps/2QrF3jyUVSU7Kutc9"
        color="inherit"
      >
        <strong>Cơ sở 2</strong>: 2K3-3K3 Nguyễn Thái Học P7 TP Vũng Tàu
      </Link>
    </Box>
    <Box mb={3}>
      <Link
        variant="body"
        underline="none"
        href="https://goo.gl/maps/a9rfP5PhMk4Rhkrb9"
        color="inherit"
      >
        <strong>Cơ sở 3</strong>: 75 Nguyễn Thái Học, P7, TP Vũng Tàu
      </Link>
    </Box>
    <Box>
      <Typography sx={{ fontStyle: 'bold' }}>
        <strong>Bà Rịa</strong>
      </Typography>
    </Box>
    <Box>
      <Link
        variant="body"
        underline="none"
        href="https://goo.gl/maps/f7pA6QRsbMY4UwNz8"
        color="inherit"
      >
        <strong>Cơ sở 4</strong>: 80 Bạch Đằng, P. Phước Trung, TP Bà Rịa
      </Link>
    </Box>
    <Box>
      <Link
        variant="body"
        underline="none"
        href="https://goo.gl/maps/Qea24P8iVwi2caFeA"
        color="inherit"
      >
        <strong>Cơ sở 5</strong>: 76 Bạch Đằng, P. Phước Trung, TP Bà Rịa
      </Link>
    </Box>
  </>
);
export const Footer = () => (
  <footer style={{ background: '#fffbf7' }}>
    <Box px={{ xs: 3, sm: 10 }} py={{ xs: 3, sm: 10 }} borderBottom={1}>
      <Grid container spacing={5}>
        <Grid item xs={12} sm={5} maxWidth="500px">
          <Box>
            <Typography gutterBottom variant="h6" color="#D14242" sx={{ fontStyle: 'bold' }}>
              Về chúng tôi
            </Typography>
          </Box>
          <Box>
            <Typography gutterBottom>CÔNG TY TNHH THE FORUM EDUCATION VIETNAM</Typography>
            <Typography gutterBottom>
              Giấy chứng nhận đăng ký kinh doanh số: 3502386218 do Sở Kế hoạch và Đầu tư tỉnh Bà Rịa
              – Vũng Tàu cấp ngày 18/01/2019. <br />
              Người đại diện theo pháp luật: Nguyễn Hoàng Huy <br />
              Địa chỉ: 27G1 Nguyễn Oanh, Phường 7, Thành phố Vũng Tàu, Tỉnh Bà Rịa – Vũng Tàu, Việt
              Nam <br />
              Số điện thoại:{' '}
              <Link underline="none" href="tel:1900886640" color="inherit">
                1900886640{' '}
              </Link>
              <br />
              Email:{' '}
              <Link underline="none" href="mailto: hr@theforumcenter.vn" color="inherit">
                {' '}
                hr@theforumcenter.vn{' '}
              </Link>
            </Typography>
          </Box>
          <Box sx={{ maxWidth: '14em', float: 'left', mt: 2 }}>
            <Link href="http://online.gov.vn/Home/WebDetails/87166">
              <img
                alt="Chứng nhận của Bộ Công Thương"
                src="https://theforumcenter.com/wp-content/uploads/2020/11/logoSaleNoti.png"
              />
            </Link>
          </Box>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Box>
            <Typography gutterBottom variant="h6" color="#D14242" sx={{ fontStyle: 'bold' }}>
              Chính sách
            </Typography>
          </Box>
          <Box>
            <Link
              variant="body"
              underline="none"
              component={RouterLink}
              to="/dieu-khoan-dich-vu"
              color="inherit"
            >
              Điều khoản dịch vụ
            </Link>
          </Box>
          <Box>
            <Link
              variant="body"
              underline="none"
              component={RouterLink}
              to="/chinh-sach-van-chuyen"
              color="inherit"
            >
              Chính sách vận chuyển
            </Link>
          </Box>
          <Box>
            <Link
              variant="body"
              underline="none"
              component={RouterLink}
              to="/chinh-sach-dat-hang"
              color="inherit"
            >
              Chính sách đặt hàng - thanh toán
            </Link>
          </Box>
          <Box>
            <Link
              variant="body"
              underline="none"
              component={RouterLink}
              to="/chinh-sach-bao-mat"
              color="inherit"
            >
              Chính sách bảo mật thông tin
            </Link>
          </Box>
          <Box>
            <Link
              variant="body"
              underline="none"
              component={RouterLink}
              to="/bao-hanh"
              color="inherit"
            >
              Chính sách bảo hành
            </Link>
          </Box>
          <Box mt={3}>
            <Typography gutterBottom variant="h6" color="#D14242" sx={{ fontStyle: 'bold' }}>
              Liên hệ
            </Typography>
          </Box>
          <Box>
            <Link variant="body" underline="none" href="https://ielts.theforum.vn" color="inherit">
              Hệ thống thi IELTS Trực tuyến
            </Link>
          </Box>
          <Box>
            <Link variant="body" underline="none" href="tel:1900886640" color="inherit">
              Hotline: 1900 886640
            </Link>
          </Box>
          <Box paddingTop={4}>
            <Link variant="body" underline="none" href="https://www.facebook.com/theforum.english">
              <SvgIcon sx={{ height: '54px', width: '54px' }}>
                <svg focusable="false" viewBox="0 0 50 50" aria-hidden="true">
                  <svg
                    width="50"
                    height="50"
                    viewBox="0 0 50 50"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35Z"
                      fill="#3B5998"
                    />
                    <path
                      d="M20.6827 18.191H18.3451V26.7547H14.8035V18.191H13.1191V15.1813H14.8035V13.2338C14.8035 11.841 15.4651 9.66016 18.3767 9.66016L21.0001 9.67113V12.5925H19.0966C18.7844 12.5925 18.3454 12.7485 18.3454 13.4129V15.1841H20.9921L20.6827 18.191Z"
                      fill="white"
                    />
                  </svg>
                </svg>
              </SvgIcon>
            </Link>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box>
            <Typography gutterBottom variant="h6" color="#D14242" sx={{ fontStyle: 'bold' }}>
              Địa chỉ
            </Typography>
          </Box>
          <AddressWrapper />
        </Grid>
      </Grid>
    </Box>
    <Box px={{ xs: 3, sm: 10 }} pt={{ xs: 1, sm: 2 }} pb={{ xs: 2, sm: 4 }}>
      <Typography variant="body2" color="#a6a6a7">
        &reg; {new Date().getFullYear()} Công ty TNHH The Forum Education Việt Nam
      </Typography>
    </Box>
  </footer>
);
