import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useRoutes } from 'react-router-dom';
import HeaderLayout from './layouts/HeaderLayout';
import AboutPage from './pages/About/AboutPage';
import { BaoHanh } from './pages/About/BaoHanh';
import { BaoMat } from './pages/About/BaoMat';
import { DatHang } from './pages/About/DatHang';
import { DieuKhoan } from './pages/About/DieuKhoan';
import { TelephoneRedirect } from './pages/About/TelephoneRedirect';
import { ThankYouPage } from './pages/About/ThankYou';
import { VanChuyen } from './pages/About/VanChuyen';
//
import Login from './pages/Auth/Login';
import Logout from './pages/Auth/Logout';
import Register from './pages/Auth/Register';
import SeoAuthPage from './pages/Auth/SeoAuthPage';
import AllCourse from './pages/Course/AllCourse';
import CourseByTagPage from './pages/Course/CourseByTagPage';
import CoursePreview from './pages/Course/CoursePreview/CoursePreview';
import CoursesHome from './pages/Course/CoursesHome';
import CreateLesson from './pages/Lesson/CreateLesson';
import CreateLessonDetail from './pages/Lesson/CreateLessonDetail';
import Payment from './pages/Payment/Payment';
import UnlockPaymentPage from './pages/Payment/UnlockPaymentPage';
import MyLesson from './pages/Profile/MyLesson';
import Profile from './pages/Profile/Profile';
import { RedirectExternal } from './utils/redirect';
import { UrlConfigs } from './utils/UrlConfig';

// ----------------------------------------------------------------------

const PrivateRouter = ({ children, redirect, authed }) => {
  const auth = useSelector((state) => state.auth);

  if (authed) {
    return auth.isAuthorized ? <Navigate to={redirect} /> : children;
  }
  return auth.isAuthorized ? children : <Navigate to={redirect} />;
};

export default function Router() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useRoutes([
    // { path: '/', element: <Navigate to={UrlConfigs.COURSES} /> },
    // {
    //   path: UrlConfigs.COURSES,
    //   element: <DashboardLayout />,
    //   children: [{ path: '', element: <CoursesHome /> }]
    // },
    {
      path: '',
      element: <HeaderLayout />,
      children: [
        {
          path: UrlConfigs.COURSES,
          element: <CoursesHome />
        },
        {
          path: UrlConfigs.LOGIN,

          element: <Login />
        },
        {
          path: UrlConfigs.REGISTER,
          element: <Register />
        },
        {
          path: UrlConfigs.LOGOUT,
          element: (
            <PrivateRouter redirect={UrlConfigs.COURSES}>
              <Logout />
            </PrivateRouter>
          )
        },
        {
          path: UrlConfigs.IELTS_TEST,
          element: <RedirectExternal path="https://ielts.theforum.vn" />
        },
        { path: UrlConfigs.ALL_COURSES, element: <AllCourse /> },

        { path: UrlConfigs.COURSE_BY_TAG, element: <CourseByTagPage /> },
        {
          path: `${UrlConfigs.LESSON_DETAIL}/:id`,
          element: <CoursePreview key={window.location.pathname} />
        },
        {
          path: UrlConfigs.PROFILE,
          element: (
            <PrivateRouter redirect={UrlConfigs.COURSES}>
              <Profile />
            </PrivateRouter>
          )
        },
        {
          path: UrlConfigs.MY_LESSON,
          element: (
            <PrivateRouter redirect={UrlConfigs.COURSES}>
              <MyLesson />
            </PrivateRouter>
          )
        },
        {
          path: UrlConfigs.PAYMENT,
          element: <Payment />
        },
        {
          path: UrlConfigs.SEOAUTH,
          element: <SeoAuthPage />
        },
        {
          path: UrlConfigs.THANKYOU_SUPPORT,
          element: <ThankYouPage />
        },
        {
          path: UrlConfigs.DIEU_KHOAN,
          element: <DieuKhoan />
        },
        {
          path: UrlConfigs.VAN_CHUYEN,
          element: <VanChuyen />
        },
        {
          path: UrlConfigs.BAO_MAT,
          element: <BaoMat />
        },
        {
          path: UrlConfigs.BAO_HANH,
          element: <BaoHanh />
        },
        {
          path: UrlConfigs.DAT_HANG,
          element: <DatHang />
        },
        {
          path: UrlConfigs.ABOUT_PAGE,
          element: <AboutPage />
        },
        {
          path: UrlConfigs.UNLOCK_PAYMENT,
          element: <UnlockPaymentPage />
        },
        {
          path: `${UrlConfigs.TELEPHONE_REDIRECT}/:phone`,
          element: <TelephoneRedirect />
        }
      ]
    },
    {
      path: UrlConfigs.CREATE_LESSON,
      children: [
        { path: '', element: <CreateLesson /> },
        { path: ':id', element: <CreateLessonDetail /> }
      ]
    }
    // { path: '*', element: <NotFound /> }
  ]);
}
