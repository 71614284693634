export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_CATEGORIES_SUCCESSFULLY = 'GET_CATEGORIES_SUCCESSFULLY';
export const GET_CATEGORIES_FAILED = 'GET_CATEGORIES_FAILED';

export function GetCategories() {
  return {
    type: GET_CATEGORIES
  };
}

export function GetCategoriesSuccessful(data) {
  return {
    type: GET_CATEGORIES_SUCCESSFULLY,
    payload: data
  };
}

export function GetCategoriesFailed(errMsg) {
  return {
    type: GET_CATEGORIES_FAILED,
    errorMessage: errMsg
  };
}
