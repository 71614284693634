import { Container, Typography } from '@material-ui/core';
import { RootStyle } from '../Course/CoursePreview/CoursePreview';

export function BaoHanh() {
  return (
    <RootStyle title="The Forum Education | Chính sách bảo hành">
      <Container sx={{ lineHeight: 2 }}>
        <Typography variant="h3" gutterBottom textAlign="center">
          Chính sách bảo hành
        </Typography>
        <p>
          <strong>1. Chính sách bảo hành</strong>
        </p>
        <p>
          Theforum.vn cam kết 100% đầu ra cho học viên, nếu học viên không đạt cam kết trong trường
          hợp đã tuân thủ đủ các nội quy học tập như trong hợp đồng cam kết giữa&nbsp;Theforum.vn và
          học viên thì học viên sẽ được&nbsp;Theforum.vn tổ chức đào tạo tiếp cho đến khi đạt cam
          kêt.
        </p>
        <p>
          <strong>2. Chính sách hủy gói dịch vụ</strong>
        </p>
        <p>
          Chúng tôi chấp nhận cho Quý khách thực hiện hủy gói dịch vụ đã thanh toán với điều kiện
          dưới đây:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <br />– Nếu quý khách hủy gói dịch vụ trong thời hạn 07 ngày kể từ ngày thanh toán và khóa
          học chưa được diễn ra, thì Theforum.vn sẽ hoàn trả 100% tiền cho những quý khách đã thanh
          toán sau khi trừ phí chuyển khoản (nếu
          có).&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <br />– Nếu quý khách hủy gói dịch vụ đã thanh toán vượt quá thời hạn trên, hoặc khóa học
          đã được diễn ra, thì Theforum.vn sẽ KHÔNG chấp nhận yêu cầu hủy
          này.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <br />
          Để biết tình trạng hiện tại của khóa học, quý khách vui lòng liên hệ theo số điện thoại
          0254 247 0979.
        </p>
      </Container>
    </RootStyle>
  );
}
