import { Container, Grid, Skeleton, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetMyLesson } from 'src/store/lesson/lesson.action';
import MyLessonCard from '../../components/profile/MyLessonCard/MyLessonCard';
import { RootStyle } from '../Course/CoursePreview/CoursePreview';
import NoLesson from './NoLesson';

const MyLesson = () => {
  const dispatch = useDispatch();
  const lessonState = useSelector((state) => state.lessons);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(lessonState.loading);
  }, [lessonState]);

  useEffect(() => {
    dispatch(GetMyLesson());
  }, []);

  return (
    <RootStyle>
      <Container>
        <Typography variant="h4" gutterBottom>
          Khóa học của tôi
        </Typography>
        {!loading && lessonState.data.myLesson?.length === 0 && <NoLesson />}
        <Grid container spacing={3} marginBottom={5}>
          {!loading ? (
            lessonState.data.myLesson
              ?.slice(0)
              .reverse()
              .map((lesson) => <MyLessonCard lesson={lesson} key={lesson._id} />)
          ) : (
            <>
              <Skeleton width="100%" height="50vh">
                <Typography>.</Typography>
              </Skeleton>
              <Skeleton width="100%" height="10vh" sx={{ transform: 'translateY(-5vh)' }}>
                <Typography>.</Typography>
              </Skeleton>
            </>
          )}
        </Grid>
      </Container>
    </RootStyle>
  );
};

export default MyLesson;
