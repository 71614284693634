import homeFill from '@iconify/icons-eva/home-fill';
import bookOpenFill from '@iconify/icons-eva/book-open-fill';
import lockFill from '@iconify/icons-eva/lock-fill';
import personAddFill from '@iconify/icons-eva/person-add-fill';
import awardFill from '@iconify/icons-eva/award-fill';
import browserFill from '@iconify/icons-eva/browser-fill';
import logoutFill from '@iconify/icons-eva/log-out-fill';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Icon } from '@iconify/react';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  // {
  //   title: 'dashboard',
  //   path: '/dashboard/app',
  //   icon: getIcon(shoppingBagFill)
  // },
  // {
  //   title: 'user',
  //   path: '/dashboard/user',
  //   icon: getIcon(peopleFill)
  // },
  // {
  //   title: 'product',
  //   path: '/dashboard/products',
  //   icon: getIcon(shoppingBagFill)
  // },
  {
    title: 'Khóa học của tôi',
    path: '/my-lesson',
    icon: getIcon(bookOpenFill),
    auth: true
  },
  {
    title: 'Tất cả khoá học',
    path: '/',
    icon: getIcon(homeFill)
  },
  {
    title: 'Làm bài IELTS',
    path: '/test',
    icon: getIcon(browserFill)
  },
  {
    title: 'Về The Forum',
    path: '/ve-chung-toi',
    icon: getIcon(awardFill)
  },
  {
    title: 'Đăng nhập',
    path: '/login',
    icon: getIcon(lockFill),
    auth: false
  },
  {
    title: 'Tài khoản',
    path: '/profile',
    icon: getIcon(personAddFill),
    auth: true
  },
  {
    title: 'Đăng ký',
    path: '/register',
    icon: getIcon(personAddFill),
    auth: false
  },
  {
    title: 'Đăng xuất',
    path: '/logout',
    icon: getIcon(logoutFill),
    auth: true
  }
  // {
  //   title: 'Tạo khóa học',
  //   path: '/create-lesson',
  //   icon: getIcon(plusFill),
  //   auth: true
  // }

  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: getIcon(alertTriangleFill)
  // }
];

export default sidebarConfig;
