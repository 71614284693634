import * as React from 'react';
import { styled } from '@material-ui/styles';
import { Box, Grid, Paper, Typography, Stack, CardMedia } from '@material-ui/core';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body1,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary
}));

export default function AboutSection() {
  return (
    <div>
      <Typography variant="h2" pt={5} pb={5} textAlign="center" data-aos="fade-down">
        Hướng dẫn học thử và đăng ký khóa học
      </Typography>
      <Box sx={{ flexGrow: 1 }} data-aos="fade-down">
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            lg={6}
            textAlign="center"
            sx={{ transform: { xl: 'translateX(5rem)', md: 'translateX(2rem)', xs: 'unset' } }}
          >
            <iframe
              width="560"
              style={{ maxWidth: '90vw' }}
              height="315"
              src="https://www.youtube.com/embed/VhLM5XW-iv0"
              title="YouTube The Forum Center IELTS SAT The Forum Education"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
            />
          </Grid>

          <Grid item xs={12} lg={6} alignSelf="center">
            {/* <Item>1. Chọn khóa học muốn học thử</Item>
            <Item>2. Xem các video học thử và mua</Item>
            <Item>3. Hệ thống sẽ lưu khóa học vào tài khoản đăng kí của bạn</Item>
            <Item>4. Bạn có học lại bao nhiêu lần tùy thích</Item> */}
            <CardMedia
              component="img"
              height="100%"
              image="static/quytrinh/quy_trinh.png"
              alt="Hướng dẫn học thử và đăng ký khóa học"
              style={{ objectFit: 'contain', width: '70%' }}
              sx={{
                margin: { lg: '0', md: '0 auto', sm: '0 auto', xs: '0 auto' },
                transform: 'translateY(-2rem)'
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
