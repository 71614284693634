export const UrlConfigs = {
  COURSES: '/',
  ALL_COURSES: '/tat-ca-khoa-hoc',
  COURSE_BY_TAG: '/khoa-hoc-theo',
  LESSON_DETAIL: '/khoa-hoc',
  IELTS_TEST: '/test',
  CREATE_LESSON: '/create',
  UNLOCK_PAYMENT: '/unlock',
  PAYMENT: '/payment',
  THANKYOU_SUPPORT: '/thankyou',
  PROFILE: '/profile',
  MY_LESSON: '/my-lesson',
  SEOAUTH: '/logged-in',
  LOGIN: '/login',
  LOGOUT: '/logout',
  REGISTER: '/register',
  DIEU_KHOAN: '/dieu-khoan-dich-vu',
  VAN_CHUYEN: '/chinh-sach-van-chuyen',
  DAT_HANG: '/chinh-sach-dat-hang',
  BAO_MAT: '/chinh-sach-bao-mat',
  BAO_HANH: '/bao-hanh',
  ABOUT_PAGE: '/ve-chung-toi',
  TELEPHONE_REDIRECT: '/call',
  NOT_FOUND: '/404'
};
