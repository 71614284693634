import {
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Link,
  Rating,
  Typography
} from '@material-ui/core';
// material
import { styled } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { React, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Link as RouterLink } from 'react-router-dom';
import Label from 'src/components/Label';
import { DISCOUNT } from 'src/constants/constant';
// utils
import { formatCurrency } from 'src/utils/formatcurrency';
import { UrlConfigs } from 'src/utils/UrlConfig';
import { IntroStyle, SubTitleStyle, TitleStyle } from './TopCourseCard';

// ----------------------------------------------------------------------

const CardMediaStyle = styled('div')({
  position: 'relative',
  paddingTop: 'calc(16%)',
  paddingLeft: '1em',
  width: '100%',
  flex: '1 30%',
  '& img': { borderRadius: '10%' }
});

const AvatarStyle = styled(Avatar)(({ theme }) => ({
  zIndex: 9,
  width: 32,
  height: 32,
  position: 'absolute',
  left: theme.spacing(3),
  bottom: theme.spacing(-2)
}));

const InfoStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexFlow: 'column',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(1),
  color: theme.palette.text.disabled
}));

const CoverImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
});

// ----------------------------------------------------------------------

ThirdCourseCard.propTypes = {
  lessons: PropTypes.object.isRequired,
  index: PropTypes.number
};

export default function ThirdCourseCard({ lessons, index, onClick }) {
  const [anchor, setAnchor] = useState(null);

  const handlePopoverOpen = (event) => {
    event.currentTarget.style.transform = 'scale(1.05)';
  };

  const handlePopoverClose = (event) => {
    event.currentTarget.style.transform = 'none';
  };

  const { thumbnail, name, price, introduction, subtitle, level, _id, seoTitle } = lessons;
  // 2 khoá học đầu bự hơn
  const latestPostLarge = [0, 1].indexOf(index) !== -1;
  // const latestPost = index === 2 || index === 3;

  // const POST_INFO = [
  //   { number: comment, icon: messageCircleFill },
  //   { number: view, icon: eyeFill },
  //   { number: share, icon: shareFill }
  // ];
  const [rateValue, setRateValue] = useState(4.5);

  return (
    <Card
      sx={{
        position: 'relative',
        height: '80%',
        transition: 'all .5s',
        background: '#fffafa'
      }}
      title={introduction}
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
      onClick={() => {
        onClick && onClick();
      }}
    >
      <CardActionArea
        LinkComponent={RouterLink}
        to={`${UrlConfigs.LESSON_DETAIL}/${seoTitle}`}
        sx={{ height: '100%', display: 'flex' }}
      >
        {DISCOUNT > 0 && (
          <Label
            variant="filled"
            color="warning"
            sx={{
              zIndex: 9,
              top: 16,
              left: 16,
              position: 'absolute',
              textTransform: 'uppercase'
            }}
          >
            -{DISCOUNT}%
          </Label>
        )}
        <CardMediaStyle>
          <CoverImgStyle alt={name} src={thumbnail} />
        </CardMediaStyle>

        <CardContent sx={{ flex: '1 80%' }}>
          <InfoStyle>
            <div>
              <TitleStyle
                variant="h6"
                sx={{
                  ...{ height: 'fit-content' }
                }}
              >
                {name}
              </TitleStyle>
              <SubTitleStyle>{ReactHtmlParser(subtitle)}</SubTitleStyle>
              <Box display="flex">
                <Typography
                  variant="h4"
                  gutterBottom
                  sx={{ color: 'red', display: 'block', mr: 1 }}
                >
                  {formatCurrency(price * (1 - DISCOUNT / 100))}
                </Typography>
                {DISCOUNT > 0 && (
                  <Typography
                    variant="body"
                    gutterBottom
                    sx={{ textDecoration: 'line-through', alignSelf: 'center' }}
                  >
                    {formatCurrency(price)}
                  </Typography>
                )}
              </Box>

              <Box component="fieldset" borderColor="transparent">
                <Rating
                  name="simple-controlled"
                  value={rateValue}
                  onChange={(event, newValue) => {
                    setRateValue(newValue);
                  }}
                  readOnly
                  precision={0.5}
                />
              </Box>
            </div>

            <IntroStyle variant="body2" mb="4em">
              {introduction}
            </IntroStyle>
          </InfoStyle>

          <Link component="div" underline="none" sx={{ alignSelf: 'end', width: '40%', pt: 3 }}>
            <Button
              fullWidth
              sx={{
                width: '20%',
                typography: 'h5',
                left: 'calc(30%)',
                bottom: 0,
                position: 'absolute',
                mb: 3
              }}
              target="_blank"
              variant="outlined"
            >
              Nhận
            </Button>
          </Link>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
