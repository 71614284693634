export function previewFileWithNewTab(base64Data, file) {
  const dataApplication = base64Data.split(';')[0];
  const mineType = dataApplication.split(':')[1];

  const blob = new Blob([file], { type: mineType });
  const fileURL = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = fileURL;
  link.target = '__blank';
  link.click();
}
