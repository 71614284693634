import { combineReducers } from 'redux';
import { authReducer } from './auth/auth.reducer';
import { categoryReducer } from './category/category.reducer';
import { couponReducer } from './coupon/coupon.reducer';
import { dialogReducer } from './dialogs/dialog.reducer';
import { exampleReducer } from './examples/example.reducer';
import { lessonAssignmentReducer } from './lesson-assignment/lesson-assignment.reducer';
import { lessonTopicReducer } from './lesson-topic/lesson-topic.reducer';
import { lessonReducer } from './lesson/lesson.reducer';
import { navigationReducer } from './navigation/navigation.reducer';
import { paymentReducer } from './payment/payment.reducer';
import { userReducer } from './user/user.reducer';

export default combineReducers({
  example: exampleReducer,
  auth: authReducer,
  categories: categoryReducer,
  lessons: lessonReducer,
  lessonTopics: lessonTopicReducer,
  navigation: navigationReducer,
  user: userReducer,
  payment: paymentReducer,
  assignment: lessonAssignmentReducer,
  dialogs: dialogReducer,
  coupon: couponReducer
});
