import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import './CustomAchivement.css';

const images = [
  {
    original:
      'https://res.cloudinary.com/forum2ea9/image/upload/q_30/v1639636639/images/student-1_lsh5hw.jpg'
  },
  {
    original:
      'https://res.cloudinary.com/forum2ea9/image/upload/q_30/v1639636639/images/student-2_qokzuu.jpg'
  },
  {
    original:
      'https://res.cloudinary.com/forum2ea9/image/upload/q_30/v1639636638/images/student-3_cpfwdq.jpg'
  },
  {
    original:
      'https://res.cloudinary.com/forum2ea9/image/upload/q_30/v1639636639/images/student-4_thhcsh.jpg'
  },
  {
    original:
      'https://res.cloudinary.com/forum2ea9/image/upload/q_30/v1639636639/images/student-5_xqejne.jpg'
  },
  {
    original:
      'https://res.cloudinary.com/forum2ea9/image/upload/q_30/v1639636637/images/student-6_erz1lv.jpg'
  },
  {
    original:
      'https://res.cloudinary.com/forum2ea9/image/upload/q_30/v1639636638/images/student-7_i3otpk.jpg'
  },
  {
    original:
      'https://res.cloudinary.com/forum2ea9/image/upload/q_30/v1639636638/images/student-8_q0bvnx.jpg'
  },
  {
    original:
      'https://res.cloudinary.com/forum2ea9/image/upload/q_30/v1639636639/images/student-9_nmj0fw.jpg'
  }
];

export function Achivement() {
  return (
    <ImageGallery
      items={images}
      infinite
      autoPlay
      showFullscreenButton={false}
      showPlayButton={false}
      showThumbnails={false}
      additionalClass="gallery-wrapper"
      slideInterval={5000}
    />
  );
}
