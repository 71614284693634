import {
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Icon,
  List,
  ListItem,
  ListItemText,
  Rating,
  Stack,
  Typography
} from '@material-ui/core';
import { Box } from '@material-ui/system';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { MotionContainer } from 'src/components/animate';
import { transitionValues } from 'src/components/animate/MotionContainer';
import Label from 'src/components/Label';
import { DISCOUNT } from 'src/constants/constant';
import { SetContentDialogSupport, ToggleDialogSupport } from 'src/store/dialogs/dialog.action';
import { formatCurrency } from 'src/utils/formatcurrency';
import { UrlConfigs } from 'src/utils/UrlConfig';
import { SubTitleStyle } from '../TopCourseCard';
import { renderTotalTime } from './CoursePreview.utils';

const CoursePreviewInfo = ({ lessonState, currentUser, isAuthorized, trialClick }) => {
  const [state, setState] = useState(lessonState);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setState(lessonState);
  }, [lessonState]);

  const handleHocThu = () => {
    if (!isAuthorized) {
      navigate(UrlConfigs.REGISTER);
    } else {
      trialClick && trialClick();
    }
  };

  return (
    <Card
      id="summary"
      sx={{
        maxWidth: '80vw',
        width: '490px',
        height: '100%',
        margin: 'auto',
        alignSelf: { xs: 'center', lg: 'unset' }
      }}
      title={state.introduction}
    >
      <CardContent sx={{ paddingTop: '3rem' }}>
        <Typography gutterBottom variant="h3" component="div" textAlign="center">
          {state.name}
        </Typography>
        <Typography gutterBottom variant="caption" component="div" textAlign="center">
          {state.introduction}
        </Typography>
        <SubTitleStyle textAlign="center">{ReactHtmlParser(state.subtitle)}</SubTitleStyle>
        {DISCOUNT > 0 && (
          <Label
            variant="filled"
            color="warning"
            sx={{
              zIndex: 9,
              top: 16,
              right: 16,
              position: 'absolute',
              textTransform: 'uppercase'
            }}
          >
            {`-${DISCOUNT}%`}
          </Label>
        )}

        <Box sx={{ textAlign: 'center' }}>
          <span
            style={{
              color: 'red',
              fontSize: '2rem',
              fontWeight: 'bold'
            }}
          >
            {formatCurrency(state.price * (1 - DISCOUNT / 100))}
          </span>{' '}
          {DISCOUNT > 0 && <strike> {formatCurrency(state.price)}</strike>}
        </Box>

        <Box>
          {state.tags
            .map((tag) => tag.title)
            .map((tagTitle) => (
              <Chip
                key={`chip-${tagTitle}`}
                sx={{ m: 0.5 }}
                label={`#${tagTitle}`}
                component={RouterLink}
                to={`${UrlConfigs.COURSE_BY_TAG}?chu-de=${tagTitle}`}
                variant="outlined"
                clickable
              />
            ))}
        </Box>

        <MotionContainer initial="initial" open>
          <motion.div
            variants={{
              animate: {
                scale: ['0.98', '0.98', '1'],
                color: ['#ff0000', '#ff0000', '#FFC107'],
                transition: transitionValues
              }
            }}
          >
            <Button
              fullWidth
              variant="contained"
              color="warning"
              sx={{ my: 1 }}
              onClick={() => {
                dispatch(
                  SetContentDialogSupport({
                    ...(currentUser.current
                      ? {
                          name: `${currentUser.current.firstName} ${currentUser.current.lastName}`,
                          phone: currentUser.current.phone
                        }
                      : { name: '', phone: '' }),
                    note: `Tôi muốn tư vấn thêm về khóa học ${state.name} `
                  })
                );
                dispatch(ToggleDialogSupport());
              }}
            >
              TƯ VẤN THÊM
            </Button>
          </motion.div>
        </MotionContainer>

        {/* <Typography variant="body2" color="text.secondary">
                Số lượng bài học: {countCourse(lessonState.data.selected?.lessonTopics)}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Thời gian: {countLength(lessonState.data.selected?.lessonTopics)}
              </Typography>  */}
      </CardContent>
      <CardContent sx={{ paddingTop: 'unset' }}>
        <Stack>
          <Box component="fieldset" borderColor="transparent">
            <Rating name="simple-controlled" value={4.5} readOnly precision={0.5} />
          </Box>

          <Button
            fullWidth
            sx={{ mt: 3 }}
            variant="outlined"
            onClick={() => {
              navigate(UrlConfigs.PAYMENT);
            }}
          >
            <Typography variant="h5">MUA</Typography>
          </Button>

          <Button fullWidth sx={{ mt: 1 }} variant="contained" onClick={handleHocThu}>
            <Typography variant="h5">Học thử</Typography>
          </Button>

          <List component="nav" style={{ marginBottom: 5 }}>
            <ListItem>
              <ListItemText
                sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  '& .MuiTypography-root': {
                    display: 'flex'
                  }
                }}
                primary={undefined}
                prefix="..."
              >
                {renderTotalTime(state.lessonTopics)}
              </ListItemText>
            </ListItem>
            {state.attributes.map((attribute, index) => (
              <ListItem key={`attributes_db_${index}`}>
                <ListItemText
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    '& .MuiTypography-root': {
                      display: 'flex'
                    }
                  }}
                  primary={attribute.name}
                  prefix="..."
                >
                  <span style={{ marginRight: 10 }}>
                    <Icon>{attribute.icon}</Icon>
                  </span>
                  {attribute.value}
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </Stack>
      </CardContent>
      <CardActions sx={{ justifyContent: 'space-between', mx: 3 }}>
        <Button
          size="small"
          href="#"
          onClick={() => {
            dispatch(ToggleDialogSupport());
          }}
        >
          Tìm hiểu thêm
        </Button>
        <Button size="small" href="#detail">
          Nội dung khóa
        </Button>
      </CardActions>
    </Card>
  );
};

CoursePreviewInfo.propTypes = {
  lessonState: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired
};

export default CoursePreviewInfo;
