import awardFill from '@iconify/icons-eva/award-fill';
import bookOpenFill from '@iconify/icons-eva/book-open-fill';
import bookOpenOutline from '@iconify/icons-eva/book-open-outline';
import browserFill from '@iconify/icons-eva/browser-fill';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
import personAddFill from '@iconify/icons-eva/person-add-fill';
import { Icon } from '@iconify/react';
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Link,
  Stack,
  Toolbar,
  Typography
} from '@material-ui/core';
// material
import { alpha, styled } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { MHidden } from 'src/components/@material-extend';
import Logo from 'src/components/Logo';
import { GetUser } from 'src/store/user/user.action';
import { UrlConfigs } from 'src/utils/UrlConfig';
import AccountPopover from './AccountPopover';
// components
//
import Searchbar from './Searchbar';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 330;
const ADS_HEIGHT = 64;
const APPBAR_MOBILE = 32;
const APPBAR_DESKTOP = 64;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.8),
  [theme.breakpoints.up('lg')]: {
    // width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
    width: '100%'
  },
  [theme.breakpoints.up('md')]: {
    // width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
    width: '100%'
  },
  left: 0,
  paddingTop: '0.1em',
  paddingBottom: '0.1em'
}));

export const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  },
  flexWrap: 'wrap-reverse'
}));

// ----------------------------------------------------------------------

NavbarSecondary.propTypes = {
  onOpenSidebar: PropTypes.func,
  closeAds: PropTypes.bool,
  setCloseAds: PropTypes.func
};

export default function NavbarSecondary({ onOpenSidebar, closeAds }) {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (!auth.loadingSignup && auth.isAuthorized && !auth.userInfo) dispatch(GetUser());
  // }, []);

  return (
    <>
      <RootStyle sx={{ top: closeAds ? '0' : '3em' }}>
        <ToolbarStyle>
          <MHidden width="lgUp">
            <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
              <Icon icon={menu2Fill} />
            </IconButton>
          </MHidden>

          <MHidden width="smDown">
            <Link component={RouterLink} to="/">
              <Logo />
            </Link>
          </MHidden>
          <MHidden width="mdDown">
            <Box sx={{ paddingLeft: 5 }} />
            <Searchbar />
          </MHidden>
          <MHidden width="xlDown">
            <Link
              underline="hover"
              variant="subtitle2"
              component={RouterLink}
              to="/"
              sx={{ ml: 3, color: 'dimgrey' }}
            >
              <Icon
                style={{ transform: 'translateY(6px)' }}
                icon={browserFill}
                width={22}
                height={22}
              />
              Trang chủ
            </Link>
          </MHidden>
          <MHidden width="lgDown">
            <Link
              underline="hover"
              variant="subtitle2"
              target="_blank"
              href="https://ielts.theforum.vn/"
              sx={{ ml: 3, color: 'dimgrey' }}
            >
              <Icon
                style={{ transform: 'translateY(6px)' }}
                icon={browserFill}
                width={22}
                height={22}
              />
              E-Learning
            </Link>
            <Link
              underline="hover"
              variant="subtitle2"
              component={RouterLink}
              to={UrlConfigs.ALL_COURSES}
              sx={{ ml: 3, color: 'dimgrey' }}
            >
              <Icon
                style={{ transform: 'translateY(6px)' }}
                icon={bookOpenOutline}
                width={22}
                height={22}
              />
              Các khóa học
            </Link>
            <Link
              underline="hover"
              variant="subtitle2"
              component={RouterLink}
              to={UrlConfigs.ABOUT_PAGE}
              sx={{ ml: 3, color: 'dimgrey' }}
            >
              <Icon
                style={{ transform: 'translateY(6px)' }}
                icon={awardFill}
                width={22}
                height={22}
              />
              Về The Forum
            </Link>
          </MHidden>
          <Box sx={{ flexGrow: 1 }} />
          <Stack direction="row" alignItems="center" spacing={3}>
            {!auth.isAuthorized && (
              <Stack direction="row">
                <Typography
                  alignSelf="center"
                  align="center"
                  variant="body2"
                  sx={{ color: 'text.primary', alignSelf: 'center' }}
                >
                  <Link component={RouterLink} underline="none" to="/register">
                    <Button
                      size="large"
                      target="_blank"
                      variant="outlined"
                      sx={{ margin: '5px 10px' }}
                      startIcon={
                        <Icon style={{ fontSize: 25, color: 'grey' }} icon={personAddFill} />
                      }
                    >
                      Đăng ký ngay
                    </Button>
                  </Link>
                  <Link underline="none" variant="subtitle2" component={RouterLink} to="/login">
                    Đăng nhập
                  </Link>
                </Typography>
              </Stack>
            )}
            {/* <LanguagePopover /> */}
            <MHidden width="mdDown">
              {auth.isAuthorized && auth.userInfo && (
                <>
                  {/* <NotificationsPopover /> */}
                  <Link
                    component={RouterLink}
                    underline="hover"
                    variant="subtitle2"
                    to={UrlConfigs.MY_LESSON}
                    sx={{ ml: 3, color: 'dimgrey', alignSelf: 'center' }}
                  >
                    <Icon
                      style={{ transform: 'translateY(6px)' }}
                      icon={bookOpenFill}
                      width={22}
                      height={22}
                    />
                    Học tập
                  </Link>
                  <AccountPopover userInfo={auth.userInfo} />
                </>
              )}
            </MHidden>
          </Stack>
        </ToolbarStyle>
      </RootStyle>
    </>
  );
}
