import {
  BottomNavigation,
  BottomNavigationAction,
  Fab,
  Icon,
  Link,
  Paper
} from '@material-ui/core';
import { styled } from '@material-ui/styles';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { ToggleDialogSupport } from 'src/store/dialogs/dialog.action';
import { MotionContainer } from '../animate';
import { transitionValues } from '../animate/MotionContainer';

export const LogoBottomStyle = styled('img')({
  objectFit: 'contain',
  height: '40px'
});

export const BottomNavigator = styled(Paper)({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 5,
  height: '70px',
  borderRadius: 'unset'
});

export const BottomNavigationActionStyle = styled(BottomNavigationAction)({
  alignSelf: 'start'
});

export const BottomNavigateContact = () => {
  const [value, setValue] = useState(2);
  const dispatch = useDispatch();

  return (
    <BottomNavigator elevation={10}>
      <BottomNavigation
        showLabels
        value={value}
        // onChange={(event, newValue) => {
        //   setValue(newValue);
        // }}
      >
        <BottomNavigationActionStyle
          LinkComponent={RouterLink}
          to="/"
          label="Home"
          icon={<Icon className="md-40">home</Icon>}
        />
        <BottomNavigationActionStyle
          LinkComponent={Link}
          target="_blank"
          href="https://m.me/theforum.english"
          label="Messenger"
          icon={
            <LogoBottomStyle
              src="/static/icons/messenger.jpg"
              alt="Liên hệ The Forum qua Messenger"
            />
          }
        />
        <BottomNavigationActionStyle
          icon={
            <MotionContainer initial="initial" open>
              <motion.div
                variants={{
                  animate: {
                    scale: ['0.98', '0.98', '1.3'],
                    transition: transitionValues
                  }
                }}
              >
                <Fab
                  aria-label="Liên hệ The Forum qua Điện Thoại"
                  sx={{
                    bottom: '-3.1em',
                    left: '-0em',
                    width: '80px',
                    height: '80px',
                    position: 'fixed',
                    backgroundColor: '#fff57a55'
                  }}
                />
              </motion.div>
              <Fab
                href="tel:1900886640"
                aria-label="Liên hệ The Forum qua Điện Thoại"
                color="primary"
                sx={{
                  bottom: '2.5em',
                  width: '80px',
                  height: '80px',
                  backgroundColor: '#ff7171'
                }}
              >
                <motion.div
                  variants={{
                    animate: {
                      rotate: ['0deg', '15deg', '30deg'],
                      transition: transitionValues
                    }
                  }}
                >
                  <Icon sx={{ color: '#fff57a' }} className="md-70">
                    phone_in_talk
                  </Icon>
                </motion.div>
              </Fab>
            </MotionContainer>
          }
        />
        <BottomNavigationActionStyle
          target="_blank"
          href="https://zalo.me/0868537506"
          label="Zalo"
          icon={<LogoBottomStyle src="/static/icons/zalo.png" alt="Liên hệ The Forum qua Zalo" />}
        />
        <BottomNavigationActionStyle
          onClick={() => {
            dispatch(ToggleDialogSupport());
          }}
          label="Tư vấn"
          icon={<Icon className="md-40">sms</Icon>}
        />
      </BottomNavigation>
    </BottomNavigator>
  );
};
