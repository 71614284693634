import { ofType } from 'redux-observable';
import { catchError, concatMap, forkJoin, map, of, switchMap, takeUntil } from 'rxjs';
import { signUp } from 'src/apis/auth.api';
import { createUserWithEmailAndPassword, updateDisplayname } from 'src/apis/firebase.api';
import { Navigate } from 'src/store/navigation/navigation.action';
import { showSuccess } from 'src/utils/toast';
import { UrlConfigs } from 'src/utils/UrlConfig';
import { LoginSuccessful } from '../signin/signin.action';
import {
  SIGNUP,
  SignUpFailed,
  SignUpGoogleSuccessful,
  SignUpSuccessful,
  SIGNUP_FAILED,
  SIGNUP_GOOGLE
} from './signup.action';

export const signUpEpic = (_action$) =>
  _action$.pipe(
    ofType(SIGNUP),
    switchMap((action) => {
      const { firstName, lastName, phone, email, password } = action.payload;
      let userInfo;
      let token;
      return createUserWithEmailAndPassword(email, password).pipe(
        concatMap((res) => {
          // UserCredential
          // co user roi
          userInfo = {
            displayName: `${firstName} ${lastName}`,
            email: res.user.email,
            photoURL: res.user.photoURL || ''
          };
          token = res.user.toJSON().stsTokenManager.accessToken;
          return forkJoin([
            updateDisplayname(`${firstName} ${lastName}`),
            signUp({ firstName, lastName, phone, email })
          ]);
        }),
        map(() => {
          showSuccess('Đăng ký tài khoản thành công');
          return SignUpSuccessful(userInfo);
        }),
        takeUntil(_action$.pipe(ofType(SIGNUP_FAILED))),
        catchError((err) => {
          console.log('err', err);
          return of(SignUpFailed(err));
        })
      );
    })
  );

export const signUpGoogleEpic = (_action$) =>
  _action$.pipe(
    ofType(SIGNUP_GOOGLE),
    switchMap((action) => {
      const { firstName, lastName, phone, email } = action.payload;
      return signUp({ firstName, lastName, phone, email }).pipe(
        map(() => {
          showSuccess('Cập nhật thông tin thành công');
          return SignUpGoogleSuccessful();
        }),
        map(() => Navigate(UrlConfigs.COURSES)),
        takeUntil(_action$.pipe(ofType(SIGNUP_FAILED))),
        catchError((err) => {
          console.log('err', err);
          return of(SignUpFailed(err));
        })
      );
    })
  );
