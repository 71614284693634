export const formatCurrency = (price) => {
  if (price === 0) return 'MIỄN PHÍ';
  return price
    ? `${Math.round(price)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}đ`
    : '';
};

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
