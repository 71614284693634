import {
  Box,
  Button,
  Card,
  CardMedia,
  Container,
  Grid,
  Icon,
  Link,
  Typography
} from '@material-ui/core';
import { styled } from '@material-ui/styles';
import { Link as RouterLink } from 'react-router-dom';
import Aos from 'aos';
import 'aos/dist/aos.css';
import React, { useEffect } from 'react';
import Gallery from 'react-grid-gallery';
import { AddressWrapper } from 'src/layouts/Footer';
import { RootStyle } from '../Course/CoursePreview/CoursePreview';
import SupportSection from '../Course/SupportSection';
import './AboutPage.css';

const IMAGES = [
  {
    src: 'https://res.cloudinary.com/forum2ea9/image/upload/v1637306212/images/slidex2_dn0ejw.jpg',
    thumbnail:
      'https://res.cloudinary.com/forum2ea9/image/upload/c_scale,w_225/v1637306212/images/slidex2_dn0ejw.jpg',
    caption: 'Địa điểm thi IELTS tại Headquater của The Forum, Nguyễn Thái Học',
    alt: 'Địa điểm thi IELTS tại Headquater của The Forum, Nguyễn Thái Học',
    tags: [
      { value: 'Địa điểm thi IELTS', title: 'Địa điểm thi IELTS' },
      { value: 'Nguyễn Thái Học', title: 'Nguyễn Thái Học' }
    ]
  },
  {
    src: 'https://res.cloudinary.com/forum2ea9/image/upload/v1637306211/images/7_bjba68.jpg',
    thumbnail:
      'https://res.cloudinary.com/forum2ea9/image/upload/c_scale,w_225/v1637306211/images/7_bjba68.jpg',
    caption: 'Địa điểm thi IELTS tại Headquater của The Forum, Nguyễn Thái Học',
    alt: 'Địa điểm thi IELTS tại Headquater của The Forum, Nguyễn Thái Học',
    tags: [
      { value: 'Địa điểm thi IELTS', title: 'Địa điểm thi IELTS' },
      { value: 'Nguyễn Thái Học', title: 'Nguyễn Thái Học' }
    ]
  },
  {
    src: 'https://res.cloudinary.com/forum2ea9/image/upload/v1637307447/images/slide-1407-1_zmcnxo.jpg',
    thumbnail:
      'https://res.cloudinary.com/forum2ea9/image/upload/c_scale,h_225/v1637307447/images/slide-1407-1_zmcnxo.jpg',
    caption:
      'Nền tảng thi IELTS được The Forum phát triển và vận hành, đảm bảo chất lượng học tốt nhất',
    alt: 'Nền tảng thi IELTS được The Forum phát triển và vận hành, đảm bảo chất lượng học tốt nhất',
    tags: [{ value: 'Test IELTS Onine', title: 'Test IELTS Onine' }]
  },
  {
    src: 'https://res.cloudinary.com/forum2ea9/image/upload/v1637307447/images/s1_xeuxtc.jpg',
    thumbnail:
      'https://res.cloudinary.com/forum2ea9/image/upload/c_scale,w_550/v1637307447/images/s1_xeuxtc.jpg',
    caption: 'Học Online, cam kết chất lượng như khi học Offline',
    alt: 'Học Online, cam kết chất lượng như khi học Offline',
    tags: [
      { value: 'IELTS Online', title: 'IELTS Online' },
      { value: 'Cam kết chất lượng', title: 'Cam kết chất lượng' }
    ]
  },
  {
    src: 'https://res.cloudinary.com/forum2ea9/image/upload/v1637306211/images/6_tzih4m.jpg',
    thumbnail:
      'https://res.cloudinary.com/forum2ea9/image/upload/c_scale,w_225/v1637306211/images/6_tzih4m.jpg',
    caption: 'Hàng chục buổi học tự do hàng tuần, nâng cao tất cả kỹ năng',
    alt: 'Hàng chục buổi học tự do hàng tuần, nâng cao tất cả kỹ năng',
    tags: [{ value: 'Buổi học tự do', title: 'Buổi học tự do' }]
  },
  {
    src: 'https://res.cloudinary.com/forum2ea9/image/upload/v1637306211/images/3_u4qoo9.jpg',
    thumbnail:
      'https://res.cloudinary.com/forum2ea9/image/upload/c_scale,w_225/v1637306211/images/3_u4qoo9.jpg',
    caption: 'Cam kết đầu ra IELTS cho tất cả khóa học',
    alt: 'Cam kết đầu ra IELTS cho tất cả khóa học',
    tags: [{ value: 'Cam kết đầu ra', title: 'Cam kết đầu ra' }]
  },
  {
    src: 'https://res.cloudinary.com/forum2ea9/image/upload/v1637306210/images/slide1a_ol1hwl.jpg',
    thumbnail:
      'https://res.cloudinary.com/forum2ea9/image/upload/c_scale,w_225/v1637306210/images/slide1a_ol1hwl.jpg',
    caption: 'Cơ sở vật chất hàng đầu khi học tại The Forum',
    alt: 'Cơ sở vật chất hàng đầu khi học tại The Forum',
    tags: [
      { value: 'Cơ sở vật chất hàng đâu', title: 'Cơ sở vật chất hàng đâu' },
      { value: 'Trải nghiệm tốt nhất', title: 'Trải nghiệm tốt nhất' }
    ]
  },
  {
    src: 'https://res.cloudinary.com/forum2ea9/image/upload/v1637307447/images/s2_ifwok2.jpg',
    thumbnail:
      'https://res.cloudinary.com/forum2ea9/image/upload/c_scale,h_225/v1637307447/images/s2_ifwok2.jpg',
    caption: 'Học Online, cam kết chất lượng như khi học Offline',
    alt: 'Học Online, cam kết chất lượng như khi học Offline',
    tags: [
      { value: 'IELTS Online', title: 'IELTS Online' },
      { value: 'Cam kết chất lượng', title: 'Cam kết chất lượng' }
    ]
  },
  {
    src: 'https://res.cloudinary.com/forum2ea9/image/upload/v1637306210/images/slidex3_crxgpd.jpg',
    thumbnail:
      'https://res.cloudinary.com/forum2ea9/image/upload/c_scale,w_225/v1637306210/images/slidex3_crxgpd.jpg',
    caption: 'Đồng tổ chức thi IELTS cùng British Council tại hội sở chính của The Forum',
    alt: 'Đồng tổ chức thi IELTS cùng British Council tại hội sở chính của The Forum',
    tags: [
      { value: 'British Council', title: 'British Council' },
      { value: 'Thi IELTS', title: 'Thi IELTS' }
    ]
  },
  {
    src: 'https://res.cloudinary.com/forum2ea9/image/upload/v1637306211/images/slidex_r8dlpv.jpg',
    thumbnail:
      'https://res.cloudinary.com/forum2ea9/image/upload/c_scale,w_225/v1637306211/images/slidex_r8dlpv.jpg',
    caption: 'Cơ sở vật chất thu IELTS Listening, mô phỏng 1-1 như thi thật',
    alt: 'Cơ sở vật chất thu IELTS Listening, mô phỏng 1-1 như thi thật',
    tags: [
      { value: 'Địa điểm thi IELTS', title: 'Địa điểm thi IELTS' },
      { value: 'Thiết bị IELTS Listening', title: 'Thiết bị IELTS Listening' }
    ]
  },
  {
    src: 'https://res.cloudinary.com/forum2ea9/image/upload/v1637307447/images/slide-1407-2_xxczac.jpg',
    thumbnail:
      'https://res.cloudinary.com/forum2ea9/image/upload/c_scale,h_225/v1637307447/images/slide-1407-2_xxczac.jpg',
    caption: 'Học Online, cam kết chất lượng như khi học Offline',
    alt: 'Học Online, cam kết chất lượng như khi học Offline',
    tags: [
      { value: 'IELTS Online', title: 'IELTS Online' },
      { value: 'Cam kết chất lượng', title: 'Cam kết chất lượng' }
    ]
  }
];

const Item = styled(Card)(({ theme }) => ({
  ...theme.typography.body1,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  background: 'rgb(48 39 39 / 60%)',
  height: '200px',
  maxHeight: '30vh',
  width: '1150px',
  maxWidth: '100vw',
  display: 'flex'
}));

const CustomCard = styled(Card)(({ theme }) => ({
  background: `url('/static/about-us/bg-card.webp')`,
  '& h3': { color: 'darkred' },
  height: '320px'
}));

export default function AboutPage() {
  useEffect(() => {
    Aos.init({
      duration: 2000
    });
  }, []);

  return (
    <RootStyle title="The Forum Education | Về Chúng Tôi" sx={{ paddingTop: 'unset' }}>
      <Box
        sx={{
          backgroundImage:
            'url(https://theforumcenter.com/wp-content/uploads/2020/11/20201003_141718.png)',
          backgroundPositionY: {
            md: '-30vw',
            xs: '0'
          },
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat'
        }}
        height="50vh"
      >
        <div
          style={{
            alignSelf: 'center',
            color: 'white',
            backgroundColor: 'rgb(255 130 130)',
            opacity: 0.3,
            position: 'absolute',
            height: '100%',
            maxHeight: '50vh',
            width: '100%'
          }}
        />
        <Item sx={{ position: 'absolute', top: '30vh' }}>
          <Typography variant="h3" color="white" alignSelf="center">
            Hãy trở thành học viên của The Forum ngay hôm nay
          </Typography>
          <Link
            component={RouterLink}
            underline="none"
            to="/"
            sx={{ alignSelf: 'end', pt: 2 }}
            alignSelf="center"
          >
            <Button
              fullWidth
              sx={{ width: '300px', maxWidth: '50vw', typography: 'h5' }}
              variant="contained"
              size="large"
            >
              Tìm hiểu khóa học
            </Button>
          </Link>
        </Item>
      </Box>
      <Container maxWidth="lg">
        <Typography variant="h1" pt={5} pb={5} textAlign="center">
          VỀ CHÚNG TÔI
        </Typography>
        <Typography
          variant="h6"
          pb={5}
          alignSelf="center"
          textAlign="center"
          sx={{ fontWeight: 600 }}
          maxWidth="700px"
          m="0 auto"
        >
          The Forum tự hào là đơn vị có bề dày kinh nghiệm trong giảng dạy IELTS và SAT theo hai
          hình thức Trực Tiếp và Trực Tuyến. Tại The Forum, học sinh không chỉ được trang bị lộ
          trình học tập toàn diện phù hợp với từng trình độ mà còn được đào tạo bài bản để tự tin
          bước vào các kỳ thi chuẩn quốc tế. The Forum cho rằng học tiếng Anh không chỉ là học một
          ngôn ngữ mới, mà còn học cách tư duy, khai phá kiến thức, học kỹ năng mềm và vốn sống để
          tự tin hòa nhập và thành công trong môi trường làm việc toàn cầu.
        </Typography>

        <Grid container pb={10}>
          <Grid item xs={12} lg={6} textAlign="center" data-aos="fade-right">
            <CardMedia
              component="img"
              height="100%"
              image="static/about-us/1.webp"
              alt="GIÁ TRỊ CỐT LÕI - The Forum Center trân trọng giá trị học thuật của ngôn ngữ, và sẽ giảng dạy tiếng Anh với tinh thần trách nhiệm cao nhất. Bên cạnh đó chúng tôi sẽ tập trung sẽ giúp cho các bạn tìm thấy được tài năng có thể chưa bộc lộ của mình, và khiến nó tỏa sáng."
              sx={{ objectFit: 'contain', maxHeight: '50vh' }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            lg={6}
            textAlign="center"
            data-aos="fade-left"
            sx={{ background: 'rgb(160,31,36)', display: 'flex' }}
          >
            <Box sx={{ alignSelf: 'center', p: '30px 50px 50px 50px' }}>
              <Typography variant="h5" color="rgb(255,238,188)" textAlign="center">
                GIÁ TRỊ CỐT LÕI
              </Typography>
              <Typography pt={2} textAlign="center" color="white">
                The Forum hiểu được việc phải cân bằng giữa lịch học văn hóa dày đặc và Tiếng Anh là
                một thử thách không nhỏ vì vậy lịch học tại The Forum được thiết kế hoàn theo lịch
                rảnh của học viên.
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            lg={6}
            textAlign="center"
            data-aos="fade-right"
            sx={{ background: 'rgb(160,31,36)', display: 'flex' }}
          >
            <Box sx={{ alignSelf: 'center', p: '30px 50px 50px 50px' }}>
              <Typography variant="h5" color="rgb(255,238,188)" textAlign="center">
                PHƯƠNG PHÁP ĐÀO TẠO
              </Typography>
              <Typography pt={2} textAlign="center" color="white">
                Tự hào là 1 trong những trung tâm Anh ngữ có uy tín và chất lượng hàng đầu với
                phương pháp luyện thi IELTS độc quyền, khoa học và ứng dụng nền tảng công nghệ trong
                giảng dạy. The Forum luôn hướng đến việc tạo môi trường học năng động, tích cực và
                tạo cảm hứng giúp bạn hoàn toàn tự tin bước vào kì thi thật
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} lg={6} textAlign="center" data-aos="fade-left">
            <CardMedia
              component="img"
              height="100%"
              image="static/about-us/2.webp"
              alt="PHƯƠNG PHÁP ĐÀO TẠO - Tự hào là 1 trong những trung tâm Anh ngữ có uy tín và chất lượng hàng đầu với phương pháp luyện thi IELTS độc quyền, khoa học và ứng dụng nền tảng công nghệ trong giảng dạy. The Forum luôn hướng đến việc tạo môi trường học năng động, tích cực và tạo cảm hứng giúp bạn hoàn toàn tự tin bước vào kì thi thật"
              sx={{ objectFit: 'contain', maxHeight: '50vh' }}
            />
          </Grid>
        </Grid>
      </Container>
      <Box
        sx={{
          background: 'darkred',
          display: 'flex',
          flexDirection: 'column',
          p: 10,
          mb: 10
        }}
      >
        <Typography variant="h3" color="rgb(255,238,188)" textAlign="center" pb={5}>
          Cơ sở vật chất
        </Typography>

        <Box maxWidth="900px" m="0 auto">
          <Gallery images={IMAGES} rowHeight={225} />,
        </Box>

        <Box textAlign="center" mb={15}>
          <Box>
            <Typography
              gutterBottom
              variant="h4"
              color="rgb(255,238,188)"
              sx={{ fontStyle: 'bold' }}
            >
              Hiện tại, The Forum đang có bốn chi nhánh khác nhau tại tỉnh Bà Rịa Vũng Tàu.
            </Typography>
          </Box>
          <Box color="white">
            <AddressWrapper />
          </Box>
        </Box>
      </Box>
      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} mb={10}>
            <Grid item xs={12} lg={6} textAlign="center" data-aos="fade-right">
              <CustomCard>
                <Icon sx={{ mt: 3, color: 'darkred' }} className="md-60">
                  watch_later
                </Icon>
                <Typography variant="h3" textAlign="center" color="dark-red">
                  Lịch học linh hoạt
                </Typography>
                <Typography p={3} textAlign="center">
                  The Forum hiểu được việc phải cân bằng giữa lịch học văn hóa dày đặc và Tiếng Anh
                  là một thử thách không nhỏ vì vậy lịch học tại The Forum được thiết kế hoàn theo
                  lịch rảnh của học viên.
                </Typography>
              </CustomCard>
            </Grid>

            <Grid item xs={12} lg={6} textAlign="center" data-aos="fade-left">
              <CustomCard>
                <Icon sx={{ mt: 3, color: 'darkred' }} className="md-60">
                  supervisor_account
                </Icon>
                <Typography variant="h3" pt={3} textAlign="center" color="dark-red">
                  Giáo viên trình độ cao cấp
                </Typography>
                <Typography p={3} textAlign="center">
                  Tại The Forum, mỗi giáo viên chỉ giảng dạy kỹ năng mà mình giỏi nhất, đồng nghĩa
                  với việc học sinh sẽ học với người giỏi nhất, bảo đảm truyền đạt kiến thức hiệu
                  quả. Các giáo viên đều sở hữu IELTS ít nhất 7.5 và kỹ năng giảng dạy ở mức 8.0+
                  hoặc Thạc sĩ.
                </Typography>
              </CustomCard>
            </Grid>
            <Grid item xs={12} lg={6} textAlign="center" data-aos="fade-right">
              <CustomCard>
                <Icon sx={{ mt: 3, color: 'darkred' }} className="md-60">
                  verified
                </Icon>
                <Typography variant="h3" pt={3} textAlign="center" color="dark-red">
                  Giáo trình chuyên biệt
                </Typography>
                <Typography p={3} textAlign="center">
                  The Forum sử dụng kết hợp giáo trình giảng dạy quốc tế đến từ các nhà xuất bản
                  hàng đầu về ngôn ngữ và bộ giáo trình được biên soạn chi tiết, thiết kế riêng cho
                  từng trình độ bởi đội ngũ chuyên môn cao tại Trung tâm giúp học viên tối đa hóa
                  điểm số.
                </Typography>
              </CustomCard>
            </Grid>
            <Grid item xs={12} lg={6} textAlign="center" data-aos="fade-left" color="dark-red">
              <CustomCard>
                <Icon sx={{ mt: 3, color: 'darkred' }} className="md-60">
                  quickreply
                </Icon>
                <Typography variant="h3" pt={3} textAlign="center">
                  Đội ngũ hỗ trợ
                </Typography>
                <Typography p={3} textAlign="center">
                  Chính sách chăm sóc hỗ trợ học viên tại The Forum sẽ mang lại cho bạn sự hài lòng
                  nhất. Mọi ý kiến và mong muốn của bạn luôn luôn được lắng nghe, tiếp thu và giải
                  quyết nhanh chóng bởi đội ngũ chăm sóc chuyên nghiệp và Trợ giảng đồng hành trong
                  suốt quá trình học tập.
                </Typography>
              </CustomCard>
            </Grid>
          </Grid>
        </Box>
      </Container>

      <SupportSection />
    </RootStyle>
  );
}
