import {
  Alert,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Link
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { UpdateForm } from 'src/components/authentication/update';

export const UpdatePopup = () => {
  const openUpdatePopup = useSelector((state) => state.auth.openUpdatePopup);
  // const needSignupState = useSelector((state) => state.user.needSignup);
  const auth = useSelector((state) => state.auth);
  const [error, setError] = useState();

  useEffect(() => {
    if (auth.errorMessage) {
      auth.errorMessage.code && setError(auth.errorMessage.code);
      auth.errorMessage.message && setError(auth.errorMessage.message);
    }
  }, [auth.errorMessage]);

  return (
    <>
      {/* <Button
        variant="contained"
        sx={{ zIndex: 10000 }}
        onClick={() => {
          dispatch(HandleLoginPopup());
        }}
      >
        TEST POPUP
      </Button> */}
      <Dialog open={openUpdatePopup}>
        <DialogTitle variant="h4" sx={{ textAlign: 'center', py: 3 }}>
          Cập nhật số điện thoại
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 5 }}>
            Hoàn thành đăng ký và tìm ngay khóa học yêu thích bạn nhé!
          </DialogContentText>
          {error && (
            <Alert severity="error" sx={{ mb: 5 }}>
              {error}
            </Alert>
          )}
          <UpdateForm user={auth.userInfo} />

          <Typography variant="body2" align="left" sx={{ color: 'text.secondary', my: 3 }}>
            Với việc Đăng ký tài khoản, tôi đồng ý với{' '}
            <Link underline="always" sx={{ color: 'text.primary' }} href="/dieu-khoan-dich-vu">
              Điều khoản dịch vụ
            </Link>{' '}
            và{' '}
            <Link underline="always" sx={{ color: 'text.primary' }} href="/chinh-sach-bao-mat">
              Chính sách bảo mật thông tin
            </Link>
            .
          </Typography>
        </DialogContent>
      </Dialog>
    </>
  );
};
