import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// ----------------------------------------------------------------------

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    /**
     * Feature chong copy
     */
    // $('body').on('cut copy paste', (event) => {
    //   event.preventDefault();
    // });

    // const selector = $('body');

    // selector
    //   .attr('unselectable', 'on')
    //   .css('user-select', 'none')
    //   .on('selectstart dragstart', false);

    // document.oncontextmenu = () => false;

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, [pathname]);

  return null;
}
