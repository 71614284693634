import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Icon,
  Stack,
  Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { STATUS_CANCELED, STATUS_PAID, STATUS_PENDING } from 'src/constants/constant';
import { formatCurrency } from 'src/utils/formatcurrency';
import { UrlConfigs } from 'src/utils/UrlConfig';

const mapStatusCode = (code) => {
  switch (code) {
    case STATUS_PAID:
      return { icon: 'check_circle', color: 'success', message: 'Hoàn thành' };
    case STATUS_PENDING:
      return { icon: 'pending_actions', color: 'warning', message: 'Đang thanh toán' };
    case STATUS_CANCELED:
      return { icon: 'error', color: 'error', message: 'Đã hủy' };
    default:
  }
};

const MyLessonCard = ({ lesson }) => {
  const status = mapStatusCode(lesson.status);

  return (
    <Grid item xs={12} sm={12} md={4}>
      <Card>
        <CardActionArea
          LinkComponent={RouterLink}
          to={`${UrlConfigs.LESSON_DETAIL}/${lesson.lesson.seoTitle}`}
        >
          <CardMedia
            component="img"
            height="194"
            image={lesson.lesson.thumbnail}
            alt={lesson.lesson.name}
            style={{ objectFit: 'contain' }}
          />
          <CardContent>
            <Typography variant="h5" color="text.secondary">
              {lesson.lesson.name}
            </Typography>
            <Typography variant="body1" color="indianred" sx={{ fontWeight: '800' }}>
              {formatCurrency(lesson.price)}
            </Typography>
            <Typography color="text.secondary">{`Thanh toán qua ${lesson.method}`}</Typography>
            <Typography color="text.secondary">{`Thời gian ${new Date(
              lesson.create_at
            ).toLocaleString()}`}</Typography>
            {lesson.transactionId && (
              <Typography color="text.secondary">{`Mã giao dịch ${lesson.transactionId}`}</Typography>
            )}
            <Stack direction="row" alignItems="center">
              <Icon sx={{ my: 1, mr: 1 }} color={status.color}>
                {status.icon}
              </Icon>
              <Typography variant="caption">{status.message}</Typography>
            </Stack>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

MyLessonCard.propTypes = {
  product: PropTypes.object
};

export default MyLessonCard;
