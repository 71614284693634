import { Dialog, DialogContent } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { ToggleDialogCod } from 'src/store/dialogs/dialog.action';
import { UrlConfigs } from 'src/utils/UrlConfig';
import CodDialogForm from './CodDialogForm';

const CodDialog = () => {
  const [sent, setSent] = useState(false);

  const codDialog = useSelector((state) => state.dialogs.codDialog);

  const dispatch = useDispatch();

  const handleToggle = () => {
    dispatch(ToggleDialogCod());
  };

  useEffect(() => {
    console.log();

    return () => {
      if (codDialog.open) {
        handleToggle();
      }
    };
  }, []);

  if (sent) return <Navigate replace to={UrlConfigs.THANKYOU_SUPPORT} />;

  return (
    <Dialog maxWidth="lg" fullWidth onClose={null} open={codDialog.open}>
      <DialogContent>
        <CodDialogForm sx={{ pb: 5 }} setSent={setSent} sent={sent} />
      </DialogContent>
    </Dialog>
  );
};

export default CodDialog;
