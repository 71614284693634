import { NAVIGATE } from './navigation.action';

const initialValues = {
  redirectTo: null
};

export function navigationReducer(state = initialValues, action) {
  switch (action.type) {
    case NAVIGATE:
      return { ...state, redirectTo: action.payload };
    default:
      return state;
  }
}
