import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AddVideoForm from '../AddVideoForm';
import CreateLessonForm from '../CreateLessonForm';
import CreateLessonTag from '../CreateLessonTags';
import VideoIntro from '../VideoIntro';

const CreateDetailContent = ({ activatedTab }) => {
  const [tab, setTab] = useState(activatedTab);

  const lessonState = useSelector((state) => state.lessons);

  useEffect(() => {
    setTab(activatedTab);
  }, [activatedTab]);

  const handleRender = () => {
    switch (tab) {
      case 'basic':
        return <CreateLessonForm />;
      case 'video':
        return <AddVideoForm lesson={lessonState.data.selected} />;
      case 'tags':
        return <CreateLessonTag lesson={lessonState.data.selected} />;
      default:
        return <VideoIntro lesson={lessonState.data.selected} />;
    }
  };

  return handleRender();
};
CreateDetailContent.propTypes = {
  activatedTab: PropTypes.string
};
export default CreateDetailContent;
