import { ofType } from 'redux-observable';
import { mergeMap, map, concat, of, switchMap } from 'rxjs';
import { logout } from 'src/apis/firebase.api';
import { Navigate } from 'src/store/navigation/navigation.action';
import { SignOutSuccessfully, SIGN_OUT } from './signout.action';

export const signOutEpic = (_action$) =>
  _action$.pipe(
    ofType(SIGN_OUT),
    mergeMap(() =>
      logout().pipe(switchMap(() => concat(of(SignOutSuccessfully()), of(Navigate(null)))))
    )
  );
