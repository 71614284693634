import { LESSON_PAYMENT_UNPAID } from 'src/constants/constant';
import {
  CHECKOUT_PAYMENT,
  CHECKOUT_PAYMENT_FAILED,
  CHECKOUT_PAYMENT_SUCCESSFULLY,
  CLEAR_PAYMENT,
  VERIFY_LESSON_FAILED,
  VERIFY_LESSON_SUCCESSFULLY,
  VERIFY_PAYMENT,
  VERIFY_PAYMENT_FAILED,
  VERIFY_PAYMENT_SUCCESSFULLY
} from './payment.action';

const initState = {
  data: null,
  verified: null,
  loading: true,
  lessonPaid: LESSON_PAYMENT_UNPAID,
  errorMessage: ''
};

export function paymentReducer(state = initState, action) {
  switch (action.type) {
    case VERIFY_PAYMENT:
    case CHECKOUT_PAYMENT:
      return { ...state, loading: true };
    case VERIFY_LESSON_SUCCESSFULLY:
      return { ...state, loading: false, lessonPaid: action.payload };
    case CHECKOUT_PAYMENT_SUCCESSFULLY:
      return { ...state, loading: false, data: action.payload };
    case VERIFY_LESSON_FAILED:
    case VERIFY_PAYMENT_FAILED:
    case CHECKOUT_PAYMENT_FAILED:
      return { ...state, loading: false, errorMessage: action.errorMessage };
    case VERIFY_PAYMENT_SUCCESSFULLY:
      return { ...state, loading: false, verified: true };
    case CLEAR_PAYMENT:
      return initState;
    default:
      return state;
  }
}
