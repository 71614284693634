import {
  Button,
  ButtonGroup,
  Slider,
  Stack,
  Box,
  LinearProgress,
  Typography
} from '@material-ui/core';
import {
  Forward5Outlined,
  Fullscreen,
  FullscreenOutlined,
  PauseCircleOutline,
  VolumeDownOutlined,
  VolumeOffOutlined,
  VolumeUpOutlined
} from '@material-ui/icons';
import PlayCircleOutline from '@material-ui/icons/PlayCircleOutline';
import React, { useEffect, useState, useRef } from 'react';
import YouTube from 'react-youtube';
import { displayYoutubeTime } from 'src/utils/formatTime';

const CUSTOM_YOUTUBE_CONTROL = false;

const LinearProgressWithLabel = (props) => (
  <Box sx={{ display: 'flex', alignItems: 'center', transform: 'translate(1%, -110%)', zIndex: 2 }}>
    <Box sx={{ width: '100%', mr: 1 }}>
      <LinearProgress variant="determinate" {...props} />
    </Box>
    <Box sx={{ width: 100 }}>
      <Typography variant="body2" color="text.secondary">
        {`${displayYoutubeTime(props.youtube.getCurrentTime())}/${displayYoutubeTime(
          props.youtube.getDuration()
        )}`}
      </Typography>
    </Box>
  </Box>
);

export const YoutubeVideoPlayer = ({ playerSrc }) => {
  /**
   * -1 (unstarted)
   * 0 (ended)
   * 1 (playing)
   * 2 (paused)
   * 3 (buffering)
   * 5 (video cued).
   */
  const [youtubeState, setYoutubeState] = useState();
  const [mute, setMute] = useState();
  const [youtube, setYoutube] = useState();
  const [progress, setProgress] = useState(0);
  const [volumn, setVolumn] = useState(0);

  const youtubeRef = useRef();

  const _onReady = (event) => {
    // access to player in all event handlers via event.target
    // console.log(event.target);
    setMute(event.target.isMuted());
    setVolumn(event.target.getVolume());
    setYoutube(event.target);
  };

  const handleStateChange = (event) => {
    setYoutubeState(event);
  };

  const handleVolumn = (event, newValue) => {
    setVolumn(newValue);
    youtube.setVolume(newValue);
  };

  useEffect(() => {
    if (!youtube) return;
    if (mute) youtube.mute();
    else youtube.unMute();
  }, [mute]);

  useEffect(() => {
    if (!youtubeState || !youtube) return;
    let timer;
    if (youtubeState.data === 1) {
      timer = setInterval(() => {
        setProgress((youtube.getCurrentTime() / youtube.getDuration()) * 100);
      }, 1000);
    } else {
      clearInterval(timer);
    }
    return () => {
      clearInterval(timer);
    };
  }, [youtubeState, youtube]);

  return (
    <Stack id="youtube-wrapper">
      <YouTube
        className={`w-100 mh-50 ${CUSTOM_YOUTUBE_CONTROL ? 'video-youtube' : ''}`}
        videoId={playerSrc}
        ref={youtubeRef}
        opts={{
          playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 1,
            modestbranding: 1,
            controls: CUSTOM_YOUTUBE_CONTROL ? 0 : 1,
            rel: 0
            // loop: 1,
            // playlist: playerSrc
          }
        }}
        onStateChange={handleStateChange}
        onReady={_onReady}
      />
      {youtubeState && CUSTOM_YOUTUBE_CONTROL && (
        <LinearProgressWithLabel value={progress} youtube={youtube} />
      )}
      {youtubeState && CUSTOM_YOUTUBE_CONTROL && (
        <Stack
          sx={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            maxWidth: '1500px',
            margin: '0 auto'
          }}
        >
          <ButtonGroup
            variant="outlined"
            aria-label="outlined button group"
            sx={{ justifyContent: 'center', flexWrap: 'wrap' }}
          >
            <Button
              onClick={() => {
                youtubeState.data === 1 ? youtube.pauseVideo() : youtube.playVideo();
              }}
            >
              {youtubeState.data === 1 ? <PauseCircleOutline /> : <PlayCircleOutline />}
            </Button>
            <Button
              onClick={() => {
                youtube.seekTo(youtube.getCurrentTime() - 5);
              }}
            >
              <Forward5Outlined sx={{ transform: 'scaleX(-1)' }} />
            </Button>
            <Button
              onClick={() => {
                youtube.seekTo(youtube.getCurrentTime() + 5);
              }}
            >
              <Forward5Outlined />
            </Button>
            <Button
              onClick={() => {
                youtube.playVideo(); // won't work on mobile
                const iframe = document.getElementsByClassName('video-youtube')[0];
                const requestFullScreen =
                  iframe.requestFullScreen ||
                  iframe.mozRequestFullScreen ||
                  iframe.webkitRequestFullScreen;
                if (requestFullScreen) {
                  requestFullScreen.bind(iframe)();
                }
              }}
            >
              <FullscreenOutlined />
            </Button>
            <Button
              onClick={() => {
                setMute(!mute);
              }}
            >
              {mute ? <VolumeOffOutlined /> : <VolumeUpOutlined />}
            </Button>
          </ButtonGroup>

          <Box sx={{ width: 200 }}>
            <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center" height="100%">
              <VolumeDownOutlined />
              <Slider
                aria-label="Volume"
                value={volumn}
                valueLabelDisplay="auto"
                step={10}
                min={0}
                max={100}
                onChange={handleVolumn}
              />
              <VolumeUpOutlined />
            </Stack>
          </Box>
        </Stack>
      )}
    </Stack>
  );
};

export default YoutubeVideoPlayer;
