export const CHECKOUT_PAYMENT = 'CHECKOUT_PAYMENT';
export const CHECKOUT_PAYMENT_FAILED = 'CHECKOUT_PAYMENT_FAILED';
export const CHECKOUT_PAYMENT_SUCCESSFULLY = 'CHECKOUT_PAYMENT_SUCCESSFULLY';
export const VERIFY_PAYMENT = 'VERIFY_PAYMENT';
export const VERIFY_PAYMENT_FAILED = 'VERIFY_PAYMENT_FAILED';
export const VERIFY_PAYMENT_SUCCESSFULLY = 'VERIFY_PAYMENT_SUCCESSFULLY';

export const UNLOCK_PAYMENT = 'UNLOCK_PAYMENT';
export const UNLOCK_PAYMENT_FAILED = 'UNLOCK_PAYMENT_FAILED';
export const UNLOCK_PAYMENT_SUCCESSFULLY = 'UNLOCK_PAYMENT_SUCCESSFULLY';

export const VERIFY_LESSON = 'VERIFY_LESSON';
export const VERIFY_LESSON_FAILED = 'VERIFY_LESSON_FAILED';
export const VERIFY_LESSON_SUCCESSFULLY = 'VERIFY_LESSON_SUCCESSFULLY';

export const CLEAR_PAYMENT = 'CLEAR_PAYMENT';

export function CheckoutPayment(paymentInfo) {
  return {
    type: CHECKOUT_PAYMENT,
    payload: paymentInfo
  };
}

export function CheckoutPaymentSuccessfully(paid) {
  return {
    type: CHECKOUT_PAYMENT_SUCCESSFULLY,
    payload: paid
  };
}

export function CheckoutPaymentFailed(errMsg) {
  return {
    type: CHECKOUT_PAYMENT_FAILED,
    errorMessage: errMsg
  };
}

export function VerifyPayment(queryParam, success) {
  return {
    type: VERIFY_PAYMENT,
    payload: queryParam,
    status: success
  };
}
export function VerifyPaymentSuccessfully(response) {
  return {
    type: VERIFY_PAYMENT_SUCCESSFULLY,
    payload: response
  };
}

export function VerifyPaymentFailed(errMsg) {
  return {
    type: VERIFY_PAYMENT_FAILED,
    errorMessage: errMsg
  };
}

export function UnlockPayment(info) {
  return {
    type: UNLOCK_PAYMENT,
    payload: info
  };
}
export function UnlockPaymentSuccessfully(response) {
  return {
    type: UNLOCK_PAYMENT_SUCCESSFULLY,
    payload: response
  };
}

export function UnlockPaymentFailed(errMsg) {
  return {
    type: UNLOCK_PAYMENT_FAILED,
    errorMessage: errMsg
  };
}

export function VerifyLesson(lessonID) {
  return {
    type: VERIFY_LESSON,
    payload: lessonID
  };
}
export function VerifyLessonSuccessfully(response) {
  return {
    type: VERIFY_LESSON_SUCCESSFULLY,
    payload: response
  };
}

export function VerifyLessonFailed(errMsg) {
  return {
    type: VERIFY_LESSON_FAILED,
    errorMessage: errMsg
  };
}

export function ClearPayment() {
  return {
    type: CLEAR_PAYMENT
  };
}
