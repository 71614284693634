export const ACCESS_TOKEN = 'access_token';
export const BACKEND_HOST = 'BACKEND_HOST';

export const START = 'START';
export const DOING = 'DOING';
export const DONE = 'DONE';
export const LEVEL = ['BEGINNER', 'INTERMEDIATE', 'ADVANCED'];
export const SELL_LEVEL = [
  { level: 1, value: 'Mục đầu tiên' },
  { level: 2, value: 'Mục thứ 2' },
  { level: 3, value: 'Mục thứ 3' }
];

export const STATUS_PAID = 'PAID';
export const STATUS_PENDING = 'PENDING';
export const STATUS_CANCELED = 'CANCELED';

export const TRIM_VIDEO_REGEX = /(\d+\.)(.+?)(\.[^.]*$|$)/;

export const LESSON_PAYMENT_PAID = 'PAID';
export const LESSON_PAYMENT_UNPAID = 'UNPAID';
export const LESSON_PAYMENT_PARENT_PAID = 'PARENT_PAID';

export const TRIM_DOCUMENT_NAME_REGEX = /(\d+)\.(\d+( )+)*([^.]*$|$)/;
export const DISCOUNT = 0.0;
