import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography
} from '@material-ui/core';
import BeenhereIcon from '@material-ui/icons/Beenhere';
import DescriptionIcon from '@material-ui/icons/Description';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { GetAllLessonTopic, PublicVideo } from '../../store/lesson-topic/lesson-topic.action';
import { displayTime } from '../../utils/formatTime';

const VideoIntro = ({ lesson }) => {
  const dispatch = useDispatch();
  const [topics, setTopics] = useState(null);

  useEffect(() => {
    if (lesson) {
      dispatch(GetAllLessonTopic(lesson._id, setTopics));
    }
  }, [lesson]);

  const handlePublicVideo = (topicId, videoId) => {
    setTopics([]);
    dispatch(PublicVideo(lesson._id, topicId, videoId, setTopics));
  };

  const renderAccordingItem = (topic) => (
    <Accordion
      key={`lesson-topic-according-${topic._id}`}
      square={false}
      defaultExpanded
      disableGutters
    >
      <AccordionSummary style={{ backgroundColor: '#f3f3f3' }}>
        <Typography component="div">{topic.name}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <List>
          {topic.lessonVideos.map((video) => (
            <ListItemButton
              key={`list-item-button-video-${video.id}`}
              onClick={() => handlePublicVideo(topic._id, video.id)}
            >
              <ListItem
                disableGutters
                secondaryAction={
                  video.is_public ? null : <BeenhereIcon sx={{ color: 'dimgrey' }} />
                }
              >
                <ListItemText primary={video.title} secondary={displayTime(video.duration)} />
              </ListItem>
            </ListItemButton>
          ))}
        </List>
      </AccordionDetails>
    </Accordion>
  );

  return (
    <div>
      {topics && topics.length > 0 ? (
        topics.map((topic) => renderAccordingItem(topic))
      ) : !topics ? (
        <CircularProgress color="warning" />
      ) : null}
    </div>
  );
};
VideoIntro.propTypes = {
  lesson: PropTypes.object
};
export default VideoIntro;
