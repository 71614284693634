import axios from 'axios';
import { defer } from 'rxjs';
import http from '../network/http';
/**
 *
 * @param {
 *  id,
 *  name,
 *  type,
 *  url
 * } lessonAssignment
 */
export function createLessonAssignment(request) {
  return defer(() => http.post('/v1/lesson-assignment', request));
}

/**
 *
 * @param {
 *  lessonId
 * }
 */
export function getAllAssignmentsByLessonId(id) {
  return defer(() => http.get(`/v1/lesson-assignment/lesson/${id}`));
}

/**
 *
 * @param {{
 * name: string,
 * course: string,
 * email: string,
 * type: string,
 * filename: string,
 * url: string
 * } request
 */
export function notifyTeamAssignment(request) {
  return axios.post(
    'https://script.google.com/macros/s/AKfycbycMm78ffaHvkuoLw4Xg-LjbJEUtlnkifeuXh6HWKLTA7GMQb_EUDOiv--16b0nYfil/exec',
    request,
    {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    }
  );
}
