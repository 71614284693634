import { Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import { ToggleDialogSupport } from 'src/store/dialogs/dialog.action';
import { UrlConfigs } from 'src/utils/UrlConfig';

const LessonTagDialog = () => {
  const [sent, setSent] = useState(false);

  const supportDialog = useSelector((state) => state.dialogs.supportDialog);

  const dispatch = useDispatch();

  const handleSetOpen = () => {
    dispatch(ToggleDialogSupport());
  };

  return (
    <Dialog fullWidth onClose={handleSetOpen} open={supportDialog.open}>
      <DialogContent>
        <Link to={`/${UrlConfigs.COURSE_BY_TAG}?chu-de=Kh%C3%B3a%20C%C6%A1%20B%E1%BA%A3n`}>
          Khóa cơ bản
        </Link>
      </DialogContent>
    </Dialog>
  );
};

export default LessonTagDialog;
