import { LOAD_DATA, LOAD_DATA_FAILED, LOAD_DATA_SUCCESSFUL } from './example.action';

const initialExampleState = {
  data: null,
  errorMessage: '',
  loading: false
};

export function exampleReducer(state = initialExampleState, action) {
  switch (action.type) {
    case LOAD_DATA:
      return { ...state, loading: true };
    case LOAD_DATA_SUCCESSFUL:
      return { ...state, loading: false, data: action.payload };
    case LOAD_DATA_FAILED:
      return { ...state, loading: false, errorMessage: action.errorMessage };
    default:
      return state;
  }
}
