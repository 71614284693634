// material
import { useEffect, useState } from 'react';
import { Container, Typography, Stack, Skeleton, Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { GetLessonAll } from 'src/store/lesson/lesson.action';
import Page from '../../components/Page';
import ThirdCourseCard from './ThirdCourseCard';
import { RootStyle } from './CoursePreview/CoursePreview';

export default function AllCourse() {
  const [loadingCourses, setLoadingCourses] = useState(true);
  const navigate = useNavigate();
  const lessonState = useSelector((state) => state.lessons);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetLessonAll());
  }, []);

  useEffect(() => {
    if (!lessonState.data.list.level1) setLoadingCourses(lessonState.loading);
  }, [lessonState]);

  return (
    <Page title="The Forum Education | Tất cả khoá học IELTS & SAT chất lượng nhất">
      <RootStyle>
        <Container maxWidth="xl">
          <Typography variant="h3" pt={5}>
            Tất cả khóa học tại The Forum
          </Typography>
          <Typography variant="body1" marginBottom={2}>
            Rèn luyện mọi kỹ năng
          </Typography>

          <Stack spacing={3}>
            {!loadingCourses ? (
              lessonState.data.list?.map((lesson, index) => (
                <ThirdCourseCard key={lesson._id} lessons={lesson} index={index} />
              ))
            ) : (
              <Skeleton width="100%" height="40vh">
                <Typography>.</Typography>
              </Skeleton>
            )}
          </Stack>
          <Button
            fullWidth
            size="large"
            variant="outlined"
            sx={{ maxWidth: '200px', mt: 3 }}
            onClick={() => navigate('/')}
          >
            Trở về trang chủ
          </Button>
        </Container>
      </RootStyle>
    </Page>
  );
}
