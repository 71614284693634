import {
  CREATE_LESSON_ASSIGNMENT,
  CREATE_LESSON_ASSIGNMENT_FAILED,
  CREATE_LESSON_ASSIGNMENT_SUCCESSFULLY,
  GET_ALL_LESSON_ASSIGNMENT,
  GET_ALL_LESSON_ASSIGNMENT_FAILED,
  GET_ALL_LESSON_ASSIGNMENT_SUCCESSFULLY
} from './lesson-assignment.action';

const initState = {
  assignments: [],
  loading: false,
  errorMessage: ''
};

export function lessonAssignmentReducer(state = initState, action) {
  switch (action.type) {
    case CREATE_LESSON_ASSIGNMENT:
    case GET_ALL_LESSON_ASSIGNMENT:
      return { ...state, loading: true };
    case GET_ALL_LESSON_ASSIGNMENT_SUCCESSFULLY:
      return { ...state, loading: false, assignments: action.payload };
    case CREATE_LESSON_ASSIGNMENT_SUCCESSFULLY:
      return { ...state, loading: false, assignments: [...state.assignments, action.payload] };
    case CREATE_LESSON_ASSIGNMENT_FAILED:
    case GET_ALL_LESSON_ASSIGNMENT_FAILED:
      return { ...state, loading: false, errorMessage: action.errorMessage };
    default:
      return state;
  }
}
