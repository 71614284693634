import bookOpenFill from '@iconify/icons-eva/book-open-fill';
import homeFill from '@iconify/icons-eva/home-fill';
import personFill from '@iconify/icons-eva/person-fill';
import { Icon } from '@iconify/react';
import { Avatar, Box, Button, Divider, IconButton, MenuItem, Typography } from '@material-ui/core';
// material
import { alpha } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import MenuPopover from 'src/components/MenuPopover';
// auth
import { SignOut } from 'src/store/auth/signout/signout.action';
import { GetUser } from 'src/store/user/user.action';
import { removeAscent } from 'src/utils/removeascent';
import { UrlConfigs } from 'src/utils/UrlConfig';
// components

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Khoá học của tôi',
    icon: bookOpenFill,
    linkTo: '/my-lesson'
  },
  {
    label: 'Trang chủ',
    icon: homeFill,
    linkTo: '/'
  },
  {
    label: 'Thông tin cá nhân',
    icon: personFill,
    linkTo: UrlConfigs.PROFILE
  }
  // },
  // {
  //   label: 'Cài đặt',
  //   icon: settings2Fill,
  //   linkTo: '#'
  // }
];

// ----------------------------------------------------------------------
AccountPopover.propTypes = {
  photoURL: PropTypes.string
};

export default function AccountPopover({ userInfo }) {
  const anchorRef = useRef(null);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState({ firstName: '', lastName: '' });
  const userState = useSelector((state) => state.user);

  useEffect(() => {
    if (userState.data.current) {
      setUser(userState.data.current);
    }
  }, [userState]);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 60,
          height: 60,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        <Avatar
          sx={{ width: 56, height: 56, backgroundColor: 'grey' }}
          src={
            userInfo.photoURL ||
            `https://ui-avatars.com/api/?name=${user.firstName}+${
              user.lastName.split(':')[0]
            }&bold=true`
          }
          alt="photoURL"
        />
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {removeAscent(`${user.firstName} ${user.lastName}`)}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {userInfo.email}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24
              }}
            />

            {option.label}
          </MenuItem>
        ))}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button
            fullWidth
            color="inherit"
            variant="outlined"
            onClick={() => {
              setOpen(false);
              dispatch(SignOut());
            }}
          >
            Đăng xuất
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
