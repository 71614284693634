import { Icon } from '@material-ui/core';

export function countLength(courses) {
  let length = 0;
  courses?.forEach((course) =>
    course.lessonVideos.forEach((video) => (length += Math.round(video.duration)))
  );
  return displayTime(length, false);
}

export function displayTime(length, isSecond = true) {
  if (!length) return null;
  const hours = Math.floor(length / (60 * 60));
  const minutes = Math.floor(length / 60) % 60;
  const seconds = length % 60;
  return (
    <span>
      {hours > 0
        ? `${hours.toLocaleString('en-US', {
            minimumIntegerDigits: 1,
            useGrouping: false
          })}  giờ `
        : ''}
      {minutes.toLocaleString('en-US', {
        minimumIntegerDigits: 1,
        useGrouping: false
      })}{' '}
      phút{' '}
      {isSecond
        ? `${seconds.toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
          })} giây`
        : ''}
    </span>
  );
}

export function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

export function renderTotalTime(course) {
  const length = countLength(course);
  return length ? (
    <>
      <span style={{ marginRight: 10 }}>
        <Icon>movie</Icon>
      </span>
      <div>Thời gian&nbsp;:&nbsp;{length}</div>
    </>
  ) : null;
}
