// material
import { Stack, TextField } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { Form, FormikProvider, useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { UpdateCurrentUser } from 'src/store/user/user.action';
import * as Yup from 'yup';
import { phoneRegExp } from '../register/RegisterForm';

// ----------------------------------------------------------------------

export default function UpdateForm({ user }) {
  const dispatch = useDispatch();
  const firstName = user.displayName?.split(' ').slice(0, 1).join(' ');
  const lastName = user.displayName?.split(' ').slice(1).join(' ');

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string().required('Vui lòng nhập họ và tên đệm'),
    lastName: Yup.string().required('Vui lòng nhập tên'),
    phone: Yup.string().matches(phoneRegExp, 'Số điện thoại không hợp lệ'),
    email: Yup.string().email('Email không hợp lệ').required('Vui lòng nhập email')
  });

  const formik = useFormik({
    initialValues: {
      firstName: firstName,
      lastName: lastName,
      phone: '',
      email: user.email
    },
    validationSchema: RegisterSchema,
    onSubmit: () => {
      // navigate('/courses', { replace: true });
      dispatch(UpdateCurrentUser({ firstName, lastName, phone: values.phone }));
    }
  });

  const { errors, touched, values, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              disabled
              label="Họ và tên dệm"
              {...getFieldProps('firstName')}
              error={Boolean(touched.firstName && errors.firstName)}
              helperText={touched.firstName && errors.firstName}
            />

            <TextField
              fullWidth
              disabled
              label="Tên"
              {...getFieldProps('lastName')}
              error={Boolean(touched.lastName && errors.lastName)}
              helperText={touched.lastName && errors.lastName}
            />
          </Stack>

          <TextField
            fullWidth
            autoComplete="tel"
            type="tel"
            label="Số điện thoại"
            focused
            {...getFieldProps('phone')}
            error={Boolean(touched.phone && errors.phone)}
            helperText={touched.phone && errors.phone}
          />

          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            disabled
            label="Địa chỉ email"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Tiếp tục
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
