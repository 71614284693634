import { ofType } from 'redux-observable';
import { catchError, map, mergeMap, of, switchMap } from 'rxjs';
import {
  createLessonAssignment,
  getAllAssignmentsByLessonId
} from 'src/apis/lesson-assignment.api';
import { showError, showSuccess } from 'src/utils/toast';
import {
  CreateLessonAssignmentFailed,
  CreateLessonAssignmentSuccessfully,
  CREATE_LESSON_ASSIGNMENT,
  GetAllLessonAssignmentFailed,
  GetAllLessonAssignmentSuccessfully,
  GET_ALL_LESSON_ASSIGNMENT
} from './lesson-assignment.action';

export const createLessonAssignmentEpic = (_action$) =>
  _action$.pipe(
    ofType(CREATE_LESSON_ASSIGNMENT),
    mergeMap((action) =>
      createLessonAssignment(action.payload).pipe(
        map((res) => {
          showSuccess('Gửi bài thành công');
          return CreateLessonAssignmentSuccessfully(res.data);
        }),
        catchError((err) => {
          console.log('err', err);
          showError('Đã có lỗi xảy ra, vui lòng thử lại sau, Create lesson assignment');
          return of(CreateLessonAssignmentFailed('Failed to create lesson assignment'));
        })
      )
    )
  );

export const getAllAssignmentsByLessonIdEpic = (_action$) =>
  _action$.pipe(
    ofType(GET_ALL_LESSON_ASSIGNMENT),
    switchMap((action) =>
      getAllAssignmentsByLessonId(action.payload).pipe(
        map((res) => GetAllLessonAssignmentSuccessfully(res.data)),
        catchError((err) => {
          console.log('err', err);
          showError('Đã có lỗi xảy ra, vui lòng thử lại, Get lesson assignment');
          return of(GetAllLessonAssignmentFailed('Failed to Get lesson assignment data'));
        })
      )
    )
  );
