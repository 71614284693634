import * as Yup from 'yup';
import { useState } from 'react';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { Navigate } from 'react-router-dom';
// material
import { Stack, TextField, IconButton, InputAdornment } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { SignUp } from 'src/store/auth/signup/signup.action';
import { useDispatch, useSelector } from 'react-redux';
import { UrlConfigs } from 'src/utils/UrlConfig';

// ----------------------------------------------------------------------
export const phoneRegExp = /^(0?)(3[2-9]|5[6|8|9]|7[0|6-9]|8[0-6|8|9]|9[0-4|6-9])[0-9]{7}$/;

export default function RegisterForm({ error, setError }) {
  const auth = useSelector((state) => state.auth);
  const navigationState = useSelector((state) => state.navigation);
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, 'Tên quá ngắn!')
      .max(20, 'Tên quá dài')
      .required('Vui lòng nhập họ và tên đệm')
      .matches(/^[^!@#^&*()./\\[\]<>%${},0123456789\-=+?:]+$/, 'Tên không hợp lệ'),
    lastName: Yup.string()
      .min(2, 'Tên quá ngắn!')
      .max(20, 'Tên quá dài')
      .required('Vui lòng nhập tên')
      .matches(/^[^!@#^&*()./\\[\]<>%${},0123456789\-=+?:]+$/, 'Tên không hợp lệ'),
    phone: Yup.string().matches(phoneRegExp, 'Số điện thoại không hợp lệ'),
    email: Yup.string().email('Email không hợp lệ').required('Vui lòng nhập email'),
    password: Yup.string()
      .min(8, 'Mật khẩu chứa ít nhất 8 ký tự')
      .required('Vui long nhập mật khẩu')
  });

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      password: ''
    },
    validationSchema: RegisterSchema,
    onSubmit: () => {
      dispatch(
        SignUp(values.firstName, values.lastName, values.phone, values.email, values.password)
      );
    }
  });

  const { errors, touched, values, handleSubmit, isSubmitting, getFieldProps } = formik;

  if (auth.isAuthorized) {
    return <Navigate to={UrlConfigs.SEOAUTH} />;
  }

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Họ và tên dệm"
              {...getFieldProps('firstName')}
              error={Boolean(touched.firstName && errors.firstName)}
              helperText={touched.firstName && errors.firstName}
            />

            <TextField
              fullWidth
              label="Tên"
              {...getFieldProps('lastName')}
              error={Boolean(touched.lastName && errors.lastName)}
              helperText={touched.lastName && errors.lastName}
            />
          </Stack>

          <TextField
            fullWidth
            autoComplete="tel"
            type="tel"
            label="Số điện thoại"
            {...getFieldProps('phone')}
            error={Boolean(touched.phone && errors.phone)}
            helperText={touched.phone && errors.phone}
          />

          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Địa chỉ email"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Mật khẩu"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting && !error}
            onClick={() => setError()}
          >
            Đăng ký
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
