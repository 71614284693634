import axios from 'axios';
import { getIdToken } from 'src/apis/firebase.api';

const http = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL
});

http.interceptors.request.use(
  async (config) => {
    // get from local storage
    const token = await getIdToken();

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => console.error(error)
);

http.interceptors.response.use(
  (response) => response,
  // eslint-disable-next-line prefer-promise-reject-errors
  ({ message }) =>
    // eslint-disable-next-line prefer-promise-reject-errors
    Promise.reject({ message })
);

export default http;
