import PropTypes from 'prop-types';
// material
import { Box, Stack } from '@material-ui/core';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  return (
    <Stack direction="row" style={{ display: 'inline-flex' }}>
      <Box
        component="img"
        alt="The Forum Education - Trung tâm luyện thi IELTS & SAT cung cấp các khóa học online IELTS và SAT"
        src="https://res.cloudinary.com/forum2ea9/image/upload/q_30/v1639636628/images/LOGO_ab17ba.png"
        sx={{ height: 72, ...sx }}
      />
      {/* <Box component="img" src="/static/TFC.png" sx={{ width: 72, height: 72, ...sx }} />
      <Box component="img" src="/static/BC.png" sx={{ height: 27, alignSelf: 'center', ...sx }} />
      <Box component="img" src="/static/IELTS.svg" sx={{ width: 72, height: 72, ...sx }} /> */}
    </Stack>
  );
}
