import { Input, TextField } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { previewFileWithNewTab } from 'src/utils/preview-file';

const UploadContainer = styled('div')(() => ({
  display: 'block'
}));

const ImageContainer = styled('div')(() => ({
  width: '100px',
  height: '100px',
  position: 'relative',
  '& img': {
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain'
  }
}));

const RemoveIcon = styled(CloseIcon)(() => ({
  position: 'absolute',
  right: 0,
  top: 0,
  fontSize: 25
}));

const FileUpload = ({
  onFileChange,
  multiple,
  label,
  defaultValue,
  accept,
  disabled,
  hideInput
}) => {
  const [imgSrc, setImgSrc] = useState(defaultValue || null);
  const [file, setFile] = useState(null);

  useEffect(() => {
    if (defaultValue) {
      setImgSrc(defaultValue);
    }
  }, [defaultValue]);

  const readFile = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      setImgSrc(e.target.result);
    };
    reader.readAsDataURL(file);
  };
  const handleOnChange = (e) => {
    const { files } = e.target;
    if (files.length > 0) {
      const file = files[0];
      setFile(file);
      if (onFileChange) {
        onFileChange(files);
      }
      readFile(file);
    }
  };

  const handleRemove = () => {
    setFile(null);
    setImgSrc('');
  };

  const handlePreviewFile = () => {
    previewFileWithNewTab(imgSrc, file);
  };

  return (
    <UploadContainer>
      <label htmlFor="upload-file">
        {label}
        {imgSrc && (
          <ImageContainer>
            <img
              role="presentation"
              src={imgSrc}
              alt=""
              onClick={handlePreviewFile}
              onKeyDown={handlePreviewFile}
            />
            <RemoveIcon onClick={handleRemove} />
          </ImageContainer>
        )}
        {!hideInput && (
          <input
            disabled={disabled}
            style={{ width: '100%' }}
            id="upload-file"
            name="upload-file"
            type="file"
            accept={accept}
            multiple={multiple}
            onChange={handleOnChange}
          />
        )}
      </label>
    </UploadContainer>
  );
};
FileUpload.propTypes = {
  onFileChange: PropTypes.func,
  multiple: PropTypes.bool,
  label: PropTypes.string,
  defaultValue: PropTypes.string,
  hideInput: PropTypes.bool
};
export default FileUpload;
