// material
import { Stack, TextField, Typography, Link } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { Form, FormikProvider, useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { sendPasswordResetEmail } from 'src/apis/firebase.api';
import { showSuccess } from 'src/utils/toast';
import * as Yup from 'yup';

// ----------------------------------------------------------------------

export default function ForgetPassword({ error, setLoginForm }) {
  const dispatch = useDispatch();

  const RegisterSchema = Yup.object().shape({
    email: Yup.string().email('Email không hợp lệ').required('Vui lòng nhập email')
  });

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: RegisterSchema,
    onSubmit: async () => {
      // navigate('/courses', { replace: true });
      // dispatch(
      //   SignUp(values.firstName, values.lastName, values.phone, values.email, values.password)
      // );
      await sendPasswordResetEmail(values.email);

      showSuccess('Kiểm tra email để lấy lại mật khẩu');
    }
  });

  const { errors, touched, values, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Typography sx={{ color: 'text.secondary' }}>
            Vui lòng cung cấp email để tiếp tục
          </Typography>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Địa chỉ email"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting && !error}
          >
            Lấy lại mật khẩu
          </LoadingButton>
          <Link
            component={RouterLink}
            variant="subtitle2"
            to="#"
            textAlign="right"
            onClick={() => setLoginForm(true)}
          >
            Trở về Đăng nhập
          </Link>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
