import { ofType } from 'redux-observable';
import { catchError, map, mergeMap, of } from 'rxjs';
import { getCurrentUserInfo, updateCurrentUserInfo } from 'src/apis/user.api';
import { showError, showSuccess } from 'src/utils/toast';
import { LOGIN_SUCCESSFULLY } from '../auth/signin/signin.action';
import { SIGNUP_SUCCESFULLY } from '../auth/signup/signup.action';
import {
  GetUserFailed,
  GetUserSuccessfully,
  GET_USER,
  UpdateCurrentUserFailed,
  UpdateCurrentUserSuccessfully,
  UPDATE_CURRENT_USER
} from './user.action';

export const getUserEpic = (_action$) =>
  _action$.pipe(
    ofType(GET_USER, LOGIN_SUCCESSFULLY, SIGNUP_SUCCESFULLY),
    mergeMap((action) =>
      getCurrentUserInfo().pipe(
        map((res) => {
          const { setUser } = action.payload;
          setUser && setUser(res.data);
          return GetUserSuccessfully(res.data);
        }),
        catchError((err) => {
          console.log('err', err);
          return of(GetUserFailed('Failed to get user'));
        })
      )
    )
  );
export const updateCurrentUserEpic = (_action$) =>
  _action$.pipe(
    ofType(UPDATE_CURRENT_USER),
    mergeMap((action) =>
      updateCurrentUserInfo(action.payload).pipe(
        map((res) => {
          showSuccess('Cập nhật thành công');
          return UpdateCurrentUserSuccessfully(res.data);
        }),
        catchError((err) => {
          console.log('err', err);
          showError('Đã có lỗi xảy ra, vui lòng thử lại sau');
          return of(UpdateCurrentUserFailed('Failed to update user'));
        })
      )
    )
  );
