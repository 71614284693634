export const checkPaymentReponse = (type, vnpResponseCode, errorCode, resultCode) => {
  switch (type) {
    case 'momo':
      return resultCode === '0';
    case 'alepay':
      return errorCode === '000';
    case 'vnpay':
      return vnpResponseCode === '00';
    default:
      return false;
  }
};

export const mapPaymentMethod = (method) => {
  if (mapPayment(method) === 'ALEPAY') {
    switch (method) {
      case 'EWALLET':
        return 'QRCODE';
      default:
        return 'ATM_ON'; // case 'ATM Nội Địa':
    }
  }
  if (mapPayment(method) === 'VNPAY') {
    switch (method) {
      case 'QR CODE':
        return 'VNPAYQR';
      case 'ATM Quốc tế':
        return 'INTCARD';
      default:
        return 'VNBANK'; // case 'ATM Nội Địa':
    }
  }
};

export const mapPayment = (method) => {
  switch (method) {
    case 'Chuyển khoản':
      return 'BANKING';
    case 'EWALLET':
      return 'ALEPAY';
    case 'MOMO':
      return 'MOMO';
    case 'ATM Nội Địa':
    case 'ATM Quốc tế':
    case 'QR CODE':
      return 'VNPAY';
    case 'COD':
      return 'COD';
    default:
      return null;
  }
};
